export default {
  data: () => ({
    isMobile: false,
  }),
  methods: {
    smallDeviceCheck() {
      window.addEventListener('resize', () => {
        this.checkDevice();
        document.body.style.zoom = 1;
      });
    },
    checkDevice() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 768) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
};
