/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import UnlistedSites from '@/services/unlistedSites.service';
import {
  FETCH_UNLISTED_SITE,
} from '../../../../actions.type';
import {
  FETCH_UNLISTED_SITE_SUCCESS,
} from '../../../../mutations.type';

export default {
  state: {
    unlistedSiteData: {},
  },
  getters: {
    unlistedSiteData(state) {
      return state.unlistedSiteData;
    },
  },
  mutations: {
    [FETCH_UNLISTED_SITE_SUCCESS](state, data) {
      Vue.set(state, 'unlistedSiteData', data.site);
    },
  },
  actions: {
    [FETCH_UNLISTED_SITE](context, id) {
      return new Promise((resolve, reject) => {
        UnlistedSites.get(id)
          .then((response) => {
            // context.commit(FETCH_UNLISTED_SITE_SUCCESS, response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
