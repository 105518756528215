import { mapGetters } from 'vuex';
import { SIDEBAR_TOGGLE } from '@/store/modules/layout/layoutActions.type';

export default {
  computed: {
    ...mapGetters([
      'isSidebarVisible',
    ]),
  },
  methods: {
    toggleSidebar() {
      this.$store.dispatch(SIDEBAR_TOGGLE).then();
    },
  },
};
