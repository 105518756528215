import LocalStorageService from '@/services/localStorage.service';

import {
  SIDEBAR_TOGGLE,
  SIDEBAR_CLOSE,
  IS_LOADING,
  FINISHED_LOADING,
  SAVE_CELL_LOADING,
  FINISH_SAVE_CELL_LOADING,
  CHANGE_CELL_LOADING,
  FINISH_CHANGE_CELL_LOADING,
  SET_LANGUAGE_APP, SET_TOUR,
} from './layoutActions.type';
import {
  SIDEBAR_TOGGLE_M,
  SIDEBAR_CLOSE_M,
  IS_LOADING_M,
  FINISHED_LOADING_M,
  SAVE_CELL_LOADING_M,
  FINISH_SAVE_CELL_LOADING_M,
  CHANGE_CELL_LOADING_M,
  FINISH_CHANGE_CELL_LOADING_M,
  SET_LANGUAGE_APP_M, SET_TOUR_M,
} from './layoutMutations.type';

export default {
  state: {
    isSidebarVisible: false,
    isLoading: false,
    saveCellLoading: false,
    changeCellLoading: false,
    appLang: LocalStorageService.getAppLanguage(),
    tour: false,
  },
  getters: {
    isSidebarVisible(state) {
      return state.isSidebarVisible;
    },
    isLoading(state) {
      return state.isLoading;
    },
    saveCellLoading(state) {
      return state.saveCellLoading;
    },
    changeCellLoading(state) {
      return state.changeCellLoading;
    },
    currentLanguageApp(state) {
      return state.appLang;
    },
    tour(state) {
      return state.tour;
    },
  },
  mutations: {
    [SIDEBAR_TOGGLE_M](state) {
      state.isSidebarVisible = !state.isSidebarVisible;
    },
    [SIDEBAR_CLOSE_M](state) {
      state.isSidebarVisible = false;
    },
    [IS_LOADING_M](state) {
      state.isLoading = true;
    },
    [FINISHED_LOADING_M](state) {
      state.isLoading = false;
    },
    [SAVE_CELL_LOADING_M](state) {
      state.saveCellLoading = true;
    },
    [FINISH_SAVE_CELL_LOADING_M](state) {
      state.saveCellLoading = false;
    },
    [CHANGE_CELL_LOADING_M](state) {
      state.changeCellLoading = true;
    },
    [FINISH_CHANGE_CELL_LOADING_M](state) {
      state.changeCellLoading = false;
    },
    [SET_LANGUAGE_APP_M](state, data) {
      state.appLang = data;
      LocalStorageService.setAppLanguage(state.appLang);
    },
    [SET_TOUR_M](state, data) {
      state.tour = data;
    },
  },
  actions: {
    [SET_LANGUAGE_APP](context, payload) {
      context.commit(SET_LANGUAGE_APP_M, payload);
    },
    [SET_TOUR](context, payload) {
      context.commit(SET_TOUR_M, payload);
    },
    [SIDEBAR_TOGGLE](context) {
      context.commit(SIDEBAR_TOGGLE_M);
    },
    [SIDEBAR_CLOSE](context) {
      context.commit(SIDEBAR_CLOSE_M);
    },
    [IS_LOADING](context) {
      context.commit(IS_LOADING_M);
    },
    [FINISHED_LOADING](context) {
      context.commit(FINISHED_LOADING_M);
    },
    [SAVE_CELL_LOADING](context) {
      context.commit(SAVE_CELL_LOADING_M);
    },
    [FINISH_SAVE_CELL_LOADING](context) {
      context.commit(FINISH_SAVE_CELL_LOADING_M);
    },
    [CHANGE_CELL_LOADING](context) {
      context.commit(CHANGE_CELL_LOADING_M);
    },
    [FINISH_CHANGE_CELL_LOADING](context) {
      context.commit(FINISH_CHANGE_CELL_LOADING_M);
    },
  },
};
