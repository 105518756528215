/* eslint-disable import/no-cycle */
import Vue from 'vue';

const MODULE_ROUTE = 'settings/menu-structure';

export default {
  get() {
    return Vue.axios.get(`${MODULE_ROUTE}`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
};
