/* eslint-disable import/no-cycle */
import Vue from 'vue';
import Vuex from 'vuex';
import layoutModule from '@/store/modules/layout/layout';
import authModule from '@/store/modules/auth/auth';
import authUserTabsModule from '@/store/modules/auth/authUserTabs/authUserTabs';
import usersModule from '@/store/modules/admin/resources/users/users';
import favPagesModule from '@/store/modules/admin/resources/favPages/favPages';
import tabCellsModule from '@/store/modules/admin/resources/tabCells/tabCells';
import unlistedSitesModule from '@/store/modules/admin/resources/unlistedSites/unlistedSites';
import sitesModule from '@/store/modules/admin/resources/sites/sites';
import mostPopularSitesModule from '@/store/modules/admin/resources/mostPopularSites/mostPopularSites';
import tabCellModule from '@/store/modules/admin/single/tabCell/tabCell';
import userTabModule from '@/store/modules/admin/single/userTab/userTab';
import favPageModule from '@/store/modules/admin/single/favPage/favPage';
import favLinesModule from '@/store/modules/admin/resources/favLine/favLine';
import favLineModule from '@/store/modules/admin/single/favLine/favLine';
import favCellModule from '@/store/modules/admin/single/favCell/favCell';
import userModule from '@/store/modules/admin/single/user/user';
import mostPopularSiteModule from '@/store/modules/admin/single/mostPopularSite/mostPopularSite';
import siteModule from '@/store/modules/admin/single/site/site';
import unlistedSiteModule from '@/store/modules/admin/single/unlistedSite/unlistSite';
import userWithoutAdminModule from '@/store/modules/user/user';
import emailModule from '@/store/modules/emails/emails';
import favPageUserModule from '@/store/modules/favPage/favPage';
import navigationStructureModule from '@/store/modules/navigationStructure/navigationStructure';
import adminDashboardModule from '@/store/modules/admin/resources/adminDashboard/adminDashboard';
import settingsModule from '@/store/modules/admin/resources/settings/settings';
import settingModule from '@/store/modules/admin/single/setting/setting';
import editorTokensModule from '@/store/modules/admin/resources/editorTokens/editorTokens';
import editorTokenModule from '@/store/modules/admin/single/editorToken/editorToken';
import subscriptionCodesModule from '@/store/modules/admin/resources/subscriptionCodes/codes';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    layout: layoutModule,
    auth: authModule,
    authUserTabs: authUserTabsModule,
    users: usersModule,
    favPages: favPagesModule,
    tabCells: tabCellsModule,
    tabCell: tabCellModule,
    userTab: userTabModule,
    favPage: favPageModule,
    favLine: favLineModule,
    favLines: favLinesModule,
    favCell: favCellModule,
    user: userModule,
    userWithoutAdmin: userWithoutAdminModule,
    sites: sitesModule,
    site: siteModule,
    unlistedSites: unlistedSitesModule,
    unlistedSite: unlistedSiteModule,
    emails: emailModule,
    mostPopularSites: mostPopularSitesModule,
    mostPopularSite: mostPopularSiteModule,
    adminDashboard: adminDashboardModule,
    favPageUser: favPageUserModule,
    navigationStructure: navigationStructureModule,
    settings: settingsModule,
    setting: settingModule,
    editorTokens: editorTokensModule,
    editorToken: editorTokenModule,
    subscriptionCodes: subscriptionCodesModule,
  },
});

export default store;
