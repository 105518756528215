/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import UnlistedSitesService from '@/services/unlistedSites.service';
import { FINISHED_LOADING, IS_LOADING } from '@/store/modules/layout/layoutActions.type';
import {
  FETCH_UNLISTED_SITES,
  UPDATE_ACTIVE_SORT_FIELD_UNLISTED_SITES,
  UPDATE_SEARCH_UNLISTED_SITES,
  CLEAR_SEARCH_UNLISTED_SITES,
  DELETE_UNLISTED_SITE,
  CREATE_UNLISTED_SITE,
  UPDATE_UNLISTED_SITE,
  REJECT_UNLISTED_SITE,
  UNFETCHD_UNLISTED_SITES_BY_TOKEN,
  MARK_UNLISTED_SITE_LIKE_OK,
} from './unlistSitesActions.type';
import {
  FETCH_UNLISTED_SITES_SUCCESS,
  UPDATE_ACTIVE_SORT_FIELD_UNLISTED_SITES_M,
  UPDATE_SEARCH_UNLISTED_SITES_M,
  CLEAR_SEARCH_UNLISTED_SITES_M,
} from './unlistedSitesMutations.type';

export default {
  state: {
    data: [],
    perPage: 25,
    currentPage: 1,
    lastPage: 1,
    from: 1,
    to: 10,
    total: 10,
    sort: [
      {
        name: 'ID ASC',
        key: 'id',
        dir: 'ASC',
        code: 'id_ASC',
        active: false,
      },
      {
        name: 'ID DESC',
        key: 'id',
        dir: 'DESC',
        code: 'id_DESC',
        active: false,
      },
      {
        name: 'Newest',
        key: 'created_at',
        dir: 'DESC',
        code: 'created_at_DESC',
        active: false,
      },
      {
        name: 'Oldest',
        key: 'created_at',
        dir: 'ASC',
        code: 'created_at_ASC',
        active: false,
      },
      {
        name: 'Updated Newest',
        key: 'updated_at',
        dir: 'DESC',
        code: 'updated_at_DESC',
        active: true,
      },
      {
        name: 'Updated Oldest',
        key: 'updated_at',
        dir: 'ASC',
        code: 'updated_at_ASC',
        active: false,
      },
      {
        name: 'A-Z by address',
        key: 'address',
        dir: 'ASC',
        code: 'address_ASC',
        active: false,
      },
      {
        name: 'Z-A by address',
        key: 'address',
        dir: 'DESC',
        code: 'address_DESC',
        active: false,
      },
      {
        name: 'Reported',
        key: 'flagged_reason',
        dir: 'DESC',
        code: 'flagged_reason_asc',
        active: false,
      },
      {
        name: 'Failed Uploaded Logo',
        key: 'uploaded_logo_successfully',
        dir: 'ASC',
        code: 'uploaded_logo_successfully_asc',
        active: false,
      },
    ],
    search: '',
  },
  getters: {
    unlistedSitesItems(state) {
      return state.data;
    },
    unlistedSitesPerPage(state) {
      return state.perPage;
    },
    unlistedSitesCurrentPage(state) {
      return state.currentPage;
    },
    unlistedSitesLastPage(state) {
      return state.lastPage;
    },
    unlistedSitesFrom(state) {
      return state.from;
    },
    unlistedSitesTo(state) {
      return state.to;
    },
    unlistedSitesTotal(state) {
      return state.total;
    },
    unlistedSitesSortFields(state) {
      return state.sort;
    },
    unlistedSitesActiveSort(state) {
      const activeSorts = state.sort.filter(sort => sort.active === true);
      return activeSorts[0];
    },
    unlistedSitesSearch(state) {
      return state.search;
    },
  },
  mutations: {
    [FETCH_UNLISTED_SITES_SUCCESS](state, data) {
      state.data = data.data;
      state.perPage = parseInt(data.perPage, 10);
      state.currentPage = data.currentPage;
      state.lastPage = data.lastPage;
      state.from = data.from;
      state.to = data.to;
      state.total = data.total;
    },
    [UPDATE_ACTIVE_SORT_FIELD_UNLISTED_SITES_M](state, data) {
      const newSortFields = [];
      state.sort.forEach((element) => {
        element.active = false;
        if (element.code === data) {
          element.active = true;
        }
        newSortFields.push(element);
      });

      Vue.set(state, 'sort', newSortFields);
    },
    [UPDATE_SEARCH_UNLISTED_SITES_M](state, data) {
      Vue.set(state, 'search', data);
    },
    [CLEAR_SEARCH_UNLISTED_SITES_M](state) {
      Vue.set(state, 'search', '');
    },
  },
  actions: {
    [FETCH_UNLISTED_SITES](context, params) {
      return new Promise((resolve, reject) => {
        // check search
        if (context.state.search && context.state.search !== '') {
          params.search = context.state.search;
        }
        // check active sort
        const activeSortArr = context.state.sort.filter(sort => sort.active === true);
        if (activeSortArr.length > 0) {
          params.sortBy = activeSortArr[0].key;
          params.sortDir = activeSortArr[0].dir;
        }

        // do the actual API call
        UnlistedSitesService.query(params)
          .then((response) => {
            context.commit(FETCH_UNLISTED_SITES_SUCCESS, response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [DELETE_UNLISTED_SITE](context, payload) {
      return new Promise((resolve, reject) => {
        context.dispatch(IS_LOADING).then(() => {
          UnlistedSitesService.destroy(payload)
            .then((response) => {
              setTimeout(() => {
                context.dispatch(FINISHED_LOADING);
                resolve(response.data);
              }, 500);
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING);
              reject(error);
            });
        });
      });
    },
    [MARK_UNLISTED_SITE_LIKE_OK](context, payload) {
      return new Promise((resolve, reject) => {
        context.dispatch(IS_LOADING).then(() => {
          UnlistedSitesService.markOk(payload)
            .then((response) => {
              setTimeout(() => {
                context.dispatch(FINISHED_LOADING);
                resolve(response.data);
              }, 500);
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING);
              reject(error);
            });
        });
      });
    },
    [UNFETCHD_UNLISTED_SITES_BY_TOKEN](context, payload) {
      return new Promise((resolve, reject) => {
        context.dispatch(IS_LOADING).then(() => {
          UnlistedSitesService.unFetchByToken(payload)
            .then((response) => {
              setTimeout(() => {
                context.dispatch(FINISHED_LOADING);
                resolve(response.data);
              }, 500);
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING);
              reject(error);
            });
        });
      });
    },
    [UPDATE_ACTIVE_SORT_FIELD_UNLISTED_SITES](context, params) {
      context.commit(UPDATE_ACTIVE_SORT_FIELD_UNLISTED_SITES_M, params);
    },
    [UPDATE_SEARCH_UNLISTED_SITES](context, params) {
      context.commit(UPDATE_SEARCH_UNLISTED_SITES_M, params);
    },
    [CLEAR_SEARCH_UNLISTED_SITES](context) {
      context.commit(CLEAR_SEARCH_UNLISTED_SITES_M);
    },
    [CREATE_UNLISTED_SITE](context, params) {
      return new Promise((resolve, reject) => {
        context.dispatch(IS_LOADING).then(() => {
        // do the actual API call
          UnlistedSitesService.create(params)
            .then((response) => {
              context.dispatch(FINISHED_LOADING);
              resolve(response.data);
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING);
              reject(error);
            });
        });
      });
    },
    [UPDATE_UNLISTED_SITE](context, params) {
      return new Promise((resolve, reject) => {
        context.dispatch(IS_LOADING).then(() => {
        // do the actual API call
          UnlistedSitesService.update(params.id, params.formData)
            .then((response) => {
              context.dispatch(FINISHED_LOADING);
              resolve(response.data);
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING);
              reject(error);
            });
        });
      });
    },
    [REJECT_UNLISTED_SITE](context, params) {
      return new Promise((resolve, reject) => {
        context.dispatch(IS_LOADING).then(() => {
        // do the actual API call
          UnlistedSitesService.reject(params.id)
            .then((response) => {
              context.dispatch(FINISHED_LOADING);
              resolve(response.data);
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING);
              reject(error);
            });
        });
      });
    },
  },
};
