export const FETCH_FAV_LINE = 'fetchFavLine';
export const FETCH_FAV_LINE_FAV_CELLS = 'fetchFavLineFavCells';
export const FETCH_FAV_CELL = 'fetchFavCell';
export const FETCH_USER = 'fetchUser';
export const FETCH_USER_BY_NAME = 'fetchUserByName';
export const FETCH_UNLISTED_SITE = 'fetchUnlistedSite';
export const FETCH_MOST_POPULAR_SITE = 'fetchMostPopularSite';
export const UPDATE_MOST_POPULAR_SITE = 'updateMostPopularSite';
export const ADMIN_MODE = 'adminMode';
export const SET_TAB_CELL_EDITABLE_ID_ADMIN = 'setTabCellEditableIDAdmin';
export const RESET_TAB_CELL_EDITABLE_ID_ADMIN = 'resetTabCellEditableIDAdmin';
