/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import FavLinesService from '@/services/favLines.service';
import {
  FETCH_FAV_LINES,
} from './favLinesActions.type';
import {
  FETCH_FAV_LINES_M,
} from './favLinesMutations.type';

export default {
  state: {
    favLinesData: [],
  },
  getters: {
    favLinesData(state) {
      return state.favLinesData;
    },
  },
  mutations: {
    [FETCH_FAV_LINES_M](state, data) {
      Vue.set(state, 'favLinesData', data);
    },
  },
  actions: {
    [FETCH_FAV_LINES](context) {
      return new Promise((resolve, reject) => {
        FavLinesService.query()
          .then((response) => {
            context.commit(FETCH_FAV_LINES_M, response.data.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
