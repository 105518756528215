/* eslint-disable arrow-body-style */
const ID_TOKEN_KEY = 'access_token';
const ID_USER_KEY = 'currentUser';
const ID_USER_TABS_KEY = 'currentUserTabs';
const LANGUAGE_APP = 'languageApp';
const NAVIGATION_STRUCTURE = 'navigationStructure';
const SELECTED_TAB_INDEX = 'selectedTabIndex';
const HIDE_LINES = 'hideLines';
const CLOSED_BANNERS = 'closedBanners';
const ACCEPT_COOKIES = 'acceptCookies';
const SHOW_MORE_TABS = 'showMoreTabs';
// TODO use utilities session

export default {
  getToken() {
    return window.localStorage.getItem(ID_TOKEN_KEY);
  },
  storeToken(token) {
    window.localStorage.setItem(ID_TOKEN_KEY, token);
  },
  destroyToken() {
    window.localStorage.removeItem(ID_TOKEN_KEY);
  },
  getUser() {
    return JSON.parse(window.localStorage.getItem(ID_USER_KEY));
  },
  storeUser(user) {
    window.localStorage.setItem(ID_USER_KEY, JSON.stringify(user));
  },
  destroyUser() {
    window.localStorage.removeItem(ID_USER_KEY);
  },
  getNavigationStructure() {
    return JSON.parse(window.localStorage.getItem(NAVIGATION_STRUCTURE));
  },
  setNavigationStructure(navStructure) {
    window.localStorage.setItem(NAVIGATION_STRUCTURE, JSON.stringify(navStructure));
  },
  getUserTabs() {
    return JSON.parse(window.localStorage.getItem(ID_USER_TABS_KEY));
  },
  storeUserTabs(userTabs) {
    window.localStorage.setItem(ID_USER_TABS_KEY, JSON.stringify(userTabs));
  },
  destroyUserTabs() {
    window.localStorage.removeItem(ID_USER_TABS_KEY);
  },
  setAppLanguage(language) {
    window.localStorage.setItem(LANGUAGE_APP, language);
  },
  getAppLanguage() {
    return window.localStorage.getItem(LANGUAGE_APP);
  },
  storeSelectedTabIndex(index) {
    window.localStorage.setItem(SELECTED_TAB_INDEX, index);
  },
  getSelectedTabIndex() {
    return window.localStorage.getItem(SELECTED_TAB_INDEX) ?? 0;
  },
  storeHideLines(value) {
    window.localStorage.setItem(HIDE_LINES, value);
  },
  getHideLines() {
    const hide = window.localStorage.getItem(HIDE_LINES);
    return (hide != null) ? (hide.toLowerCase() === 'true') : false;
  },
  setClosedBanners(page, banner) {
    const closedBanners = this.getClosedBanners();

    let isClosedBefore = false;
    (closedBanners).forEach((element) => {
      if (element.page === page) {
        /* eslint no-param-reassign: "error" */
        element.banner = banner;
        isClosedBefore = true;
      }
    });
    if (!isClosedBefore) {
      closedBanners.push({ page, banner });
    }
    window.localStorage.setItem(CLOSED_BANNERS, JSON.stringify(closedBanners));
  },
  getClosedBanners() {
    const closedBanners = JSON.parse(window.localStorage.getItem(CLOSED_BANNERS));
    return (closedBanners != null && closedBanners !== undefined) ? closedBanners : [];
  },
  setAcceptCookies() {
    window.localStorage.setItem(ACCEPT_COOKIES, JSON.stringify(true));
  },
  getAcceptCookies() {
    const acceptCookies = JSON.parse(window.localStorage.getItem(ACCEPT_COOKIES));
    return (acceptCookies != null && acceptCookies !== undefined) ? acceptCookies : false;
  },
  setShowMoreTabs(value) {
    window.localStorage.setItem(SHOW_MORE_TABS, JSON.stringify(value));
  },
  getShowMoreTabs() {
    const showMoreTabs = JSON.parse(window.localStorage.getItem(SHOW_MORE_TABS));
    return (showMoreTabs != null && showMoreTabs !== undefined) ? showMoreTabs : false;
  },
};
