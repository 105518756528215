/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import UsersService from '@/services/users.service';
import { FETCH_ADMIN_USER } from '@/store/modules/admin/resources/users/usersActions.type';
import {
  ADMIN_MODE,
  FETCH_USER, FETCH_USER_BY_NAME, RESET_TAB_CELL_EDITABLE_ID_ADMIN, SET_TAB_CELL_EDITABLE_ID_ADMIN,
} from '../../../../actions.type';
import {
  ADMIN_MODE_SUCCESS,
  FETCH_USER_SUCCESS, RESET_TAB_CELL_EDITABLE_ID_ADMIN_M, SET_TAB_CELL_EDITABLE_ID_ADMIN_M,
} from '../../../../mutations.type';
import { FINISHED_LOADING, IS_LOADING } from '../../../layout/layoutActions.type';

export default {
  state: {
    userData: {},
    adminMode: false,
    selectedTabCellIdToEditAdmin: null,
  },
  getters: {
    userData(state) {
      return state.userData;
    },
    adminMode(state) {
      return state.adminMode;
    },
    selectedTabCellIdToEditAdmin(state) {
      return state.selectedTabCellIdToEditAdmin;
    },
    selectedTabCellObjToEditAdmin(state, getters) {
      if (state.selectedTabCellIdToEditAdmin) {
        return state.userData.userTabs[getters.selectedTabIndex] && state.userData.userTabs[getters.selectedTabIndex].tabCells.find(item => item.id === state.selectedTabCellIdToEditAdmin)
          ? state.userData.userTabs[getters.selectedTabIndex].tabCells.find(item => item.id === state.selectedTabCellIdToEditAdmin) // update cell
          : state.selectedTabCellIdToEditAdmin; // add cell
      }
      return state.userData.userTabs && state.userData.userTabs[getters.selectedTabIndex] ? state.userData.userTabs[getters.selectedTabIndex].id : 0;
    },
  },
  mutations: {
    [FETCH_USER_SUCCESS](state, data) {
      Vue.set(state, 'userData', data.user);
    },
    [ADMIN_MODE_SUCCESS](state, data) {
      Vue.set(state, 'adminMode', data);
    },
    [SET_TAB_CELL_EDITABLE_ID_ADMIN_M](state, data) {
      state.selectedTabCellIdToEditAdmin = data;
    },
    [RESET_TAB_CELL_EDITABLE_ID_ADMIN_M](state) {
      state.selectedTabCellIdToEditAdmin = null;
    },
  },
  actions: {
    [FETCH_USER](context, id) {
      return new Promise((resolve, reject) => {
        UsersService.get(id)
          .then((response) => {
            context.commit(FETCH_USER_SUCCESS, response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [FETCH_ADMIN_USER](context, id) {
      return new Promise((resolve, reject) => {
        context.dispatch(IS_LOADING).then(() => {
          UsersService.getAdminMode(id)
            .then((response) => {
              context.commit(FETCH_USER_SUCCESS, response.data.data);
              context.dispatch(FINISHED_LOADING).then(() => {
                resolve(response.data);
              });
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING).then(() => {
                reject(error);
              });
            });
        });
      });
    },
    [FETCH_USER_BY_NAME](context, name) {
      return new Promise((resolve, reject) => {
        context.dispatch(IS_LOADING).then(() => {
          UsersService.getUserByName(name)
            .then((response) => {
              context.commit(FETCH_USER_SUCCESS, response.data.data);
              context.dispatch(FINISHED_LOADING).then(() => {
                resolve(response.data);
              });
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING).then(() => {
                reject(error);
              });
            });
        });
      });
    },
    [ADMIN_MODE](context, payload) {
      context.commit(ADMIN_MODE_SUCCESS, payload);
    },
    [SET_TAB_CELL_EDITABLE_ID_ADMIN](context, payload) {
      context.commit(SET_TAB_CELL_EDITABLE_ID_ADMIN_M, payload);
    },
    [RESET_TAB_CELL_EDITABLE_ID_ADMIN](context) {
      context.commit(RESET_TAB_CELL_EDITABLE_ID_ADMIN_M);
    },
  },
};
