/* eslint-disable import/no-cycle,no-shadow */
import LocalStorageService from '@/services/localStorage.service';
import ApiService from '@/services/api.service';
import router from '@/router/index';
import {
  FINISHED_LOADING,
  IS_LOADING,
  SIDEBAR_CLOSE,
} from '@/store/modules/layout/layoutActions.type';
import {
  SET_USER, CHECK_CONFIRM_ACCOUNT, RESEND_CONFIRM_EMAIL, CONFIRM_ACCOUNT, FORGOT_PASSWORD, GET_AUTH_USER_TABS, LOGIN, LOGOUT, REGISTER, RESET_PASSWORD, SOCIAL_LOGIN, SOCIAL_LOGIN_SUCCESS,
} from './authActions.type';
import {
  LOGIN_SUCCESS,
  PURGE_AUTH,
  SET_USER_M,
} from './authMutations.type';

const state = {
  user: LocalStorageService.getUser(),
  token: LocalStorageService.getToken(),
  isAuthenticated: !!LocalStorageService.getToken(),
};

const getters = {
  user(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  isAdmin(state) {
    if (!state.isAuthenticated) {
      return false;
    }
    return state.user.role === 'admin';
  },
};

const actions = {
  [LOGIN](context, payload) {
    const loginRoute = '/auth/user/login';
    return new Promise((resolve, reject) => {
      context.dispatch(IS_LOADING)
        .then(() => {
          ApiService.post(loginRoute, payload)
            .then((response) => {
              context.commit(LOGIN_SUCCESS, response.data.data);
              context.dispatch(GET_AUTH_USER_TABS)
                .then(() => {
                  context.dispatch(FINISHED_LOADING)
                    .then(() => {
                      resolve(response.data);
                    });
                });
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING)
                .then(() => {
                  reject(error);
                });
            });
        });
    });
  },
  [SOCIAL_LOGIN](context, payload) {
    const loginRoute = `/socialAuth/redirect/${payload}`;
    return new Promise((resolve, reject) => {
      context.dispatch(IS_LOADING)
        .then(() => {
          ApiService.socialLogin(loginRoute)
            .then((response) => {
              context.dispatch(FINISHED_LOADING)
                .then(() => {
                  resolve(response.data);
                });
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING)
                .then(() => {
                  reject(error);
                });
            });
        });
    });
  },
  [SOCIAL_LOGIN_SUCCESS](context, payload) {
    const loginRoute = `/socialAuth/${payload.provider}/callback?code=${payload.code}`;
    return new Promise((resolve, reject) => {
      context.dispatch(IS_LOADING)
        .then(() => {
          ApiService.socialLogin(loginRoute)
            .then((response) => {
              context.commit(LOGIN_SUCCESS, response.data.data);
              context.dispatch(GET_AUTH_USER_TABS);
              context.dispatch(FINISHED_LOADING)
                .then(() => {
                  resolve(response.data);
                });
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING)
                .then(() => {
                  reject(error);
                });
            });
        });
    });
  },
  [REGISTER](context, payload) {
    const registerRoute = '/auth/user/register';
    return new Promise((resolve, reject) => {
      context.dispatch(IS_LOADING)
        .then(() => {
          ApiService.post(registerRoute, payload)
            .then((response) => {
              context.dispatch(FINISHED_LOADING)
                .then(() => {
                  resolve(response.data);
                });
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING)
                .then(() => {
                  reject(error);
                });
            });
        });
    });
  },
  [CONFIRM_ACCOUNT](context, payload) {
    const confirmAccountRoute = '/auth/user/confirm-account';
    return new Promise((resolve, reject) => {
      context.dispatch(IS_LOADING)
        .then(() => {
          ApiService.post(confirmAccountRoute, payload)
            .then((response) => {
              context.commit(LOGIN_SUCCESS, response.data.data);
              context.dispatch(GET_AUTH_USER_TABS);
              context.dispatch(FINISHED_LOADING)
                .then(() => {
                  resolve(response.data);
                });
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING)
                .then(() => {
                  reject(error);
                });
            });
        });
    });
  },
  [CHECK_CONFIRM_ACCOUNT](context, payload) {
    const confirmAccountRoute = '/auth/user/check-confirm-account';
    return new Promise((resolve, reject) => {
      context.dispatch(IS_LOADING)
        .then(() => {
          ApiService.post(confirmAccountRoute, payload)
            .then((response) => {
              context.dispatch(FINISHED_LOADING)
                .then(() => {
                  resolve(response.data);
                });
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING)
                .then(() => {
                  reject(error);
                });
            });
        });
    });
  },
  [RESEND_CONFIRM_EMAIL](context, payload) {
    const resendConfirmEmailRoute = '/auth/user/resend-confirm-email';
    return new Promise((resolve, reject) => {
      context.dispatch(IS_LOADING)
        .then(() => {
          ApiService.post(resendConfirmEmailRoute, payload)
            .then((response) => {
              context.dispatch(FINISHED_LOADING)
                .then(() => {
                  resolve(response.data);
                });
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING)
                .then(() => {
                  reject(error);
                });
            });
        });
    });
  },
  [RESET_PASSWORD](context, payload) {
    const resetPasswordRoute = '/auth/user/reset-password';
    return new Promise((resolve, reject) => {
      context.dispatch(IS_LOADING)
        .then(() => {
          ApiService.post(resetPasswordRoute, payload)
            .then((response) => {
              context.dispatch(FINISHED_LOADING)
                .then(() => {
                  resolve(response.data);
                });
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING)
                .then(() => {
                  reject(error);
                });
            });
        });
    });
  },
  [FORGOT_PASSWORD](context, payload) {
    const forgotPasswordRoute = '/auth/user/forgot-password';
    return new Promise((resolve, reject) => {
      context.dispatch(IS_LOADING)
        .then(() => {
          ApiService.post(forgotPasswordRoute, payload)
            .then((response) => {
              context.dispatch(FINISHED_LOADING)
                .then(() => {
                  resolve(response.data);
                });
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING)
                .then(() => {
                  reject(error);
                });
            });
        });
    });
  },
  [LOGOUT](context) {
    return new Promise((resolve, reject) => {
      context.dispatch(IS_LOADING)
        .then(() => {
          ApiService.get('auth/logout')
            .then((response) => {
              context.commit(PURGE_AUTH);
              context.commit(SIDEBAR_CLOSE);
              resolve(response);
            })
            .catch((error) => {
              context.commit(PURGE_AUTH);
              context.commit(SIDEBAR_CLOSE);
              context.dispatch(FINISHED_LOADING)
                .then(() => {
                  reject(error);
                });
            });
        });
    });
  },
  [SET_USER](context, payload) {
    context.commit(SET_USER_M, payload);
  },
};

const mutations = {
  [LOGIN_SUCCESS](state, data) {
    state.isAuthenticated = true;
    state.user = data.user;
    LocalStorageService.storeUser(data.user);
    LocalStorageService.storeToken(data.access_token);
    router.push({ name: 'home' });
  },
  [PURGE_AUTH](state) {
    LocalStorageService.destroyToken();
    LocalStorageService.destroyUser();
    LocalStorageService.destroyUserTabs();
    state.isAuthenticated = false;
    state.user = null;
    router.push({ name: 'home' });
  },
  [SET_USER_M](state, user) {
    state.user = user;
    LocalStorageService.storeUser(user);
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
