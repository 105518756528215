<template>
  <div class="md-layout md-alignment-center-center search-container">
    <div style="width: 70%;" class="md-layout-item md-size-60 md-alignment-center-center searchbar-item md-small-size-50">
      <form :action="searchAction" method="get" target="_blank" id="search-form" @submit.prevent="search">
        <md-autocomplete :class="invalidQuery"
          v-model="googleSearchSelected"
          :md-options="searchItems"
          md-layout="box"
          md-min-length="1"
          md-dense data-gname="storesearch"
          title="Enter your query and then select the search engine">
          <label>Google Search</label>
        </md-autocomplete>
      </form>
    </div>
    <div class="md-alignment searchbar-items" style="z-index: 0;">
      <div class="md-layout-item md-size-100">
        <md-button title="Google Search" class="md-icon-button md-raised md-primary google-btn" @click="search('google')">
          <img :src="`${publicPath}google-icon.svg`">
        </md-button>
        <md-button title="Amazon Search" class="md-icon-button md-raised md-accent amazon-btn" @click="search('amazon')">
          <i class="fab fa-amazon"></i>
        </md-button>
        <md-button title="Youtube Search" class="md-icon-button md-raised md-primary youtube-btn" @click="search('youtube')">
          <i class="fab fa-youtube"></i>
        </md-button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'SearchBar',
  data: () => ({
    googleSearchSelected: '',
    searchItems: [],
    searchAction: null,
    publicPath: process.env.BASE_URL,
    html: '',
  }),
  created() {
    const queryParamter = window.location.search.toString().substring(1, 2);
    let query = '';
    if (queryParamter === 'q') {
      query = window.location.search.toString().substring(3);
    }
    this.googleSearchSelected = query;
  },
  methods: {
    search(website) {
      if (this.googleSearchSelected) {
        const query = this.googleSearchSelected;
        this.googleSearchSelected = '';
        switch (website) {
          case 'google':
            window.open(this.$router.resolve({ path: `/google/search?q=${query}` }).href, '_blank');
            break;
          case 'amazon':
            this.searchAction = `https://www.amazon.com/s?k=${query}`;
            window.open(this.searchAction, '_blank');
            break;
          case 'youtube':
            this.searchAction = `https://www.youtube.com/results?search_query=${query}`;
            window.open(this.searchAction, '_blank');
            break;
          default:
            window.open(this.$router.resolve({ path: `/google/search?q=${query}` }).href, '_blank');
        }
      } else {
        this.googleSearchSelected = null; // to show the border
        setTimeout(() => {
          this.googleSearchSelected = '';
        }, 500);
      }
    },
  },
  computed: {
    invalidQuery() {
      return this.googleSearchSelected != null ? '' : 'invalid-query';
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/layout/search-bar.scss';
</style>
