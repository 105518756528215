export default {
  data: () => ({
    requiredFields: false,
    isEditMode: false,
    errors: {},
    publicPath: process.env.BASE_URL,
  }),
  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty,
        };
      }

      return {
        'md-invalid': true,
      };
    },
    clearErrors(field) {
      this.errors[field] = [];
    },
  },
};
