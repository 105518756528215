/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import SitesService from '@/services/sites.service';
import { FINISHED_LOADING, IS_LOADING } from '@/store/modules/layout/layoutActions.type';
import {
  CHECK_SITE, CREATE_ADMIN_SITE,
  CREATE_SITE,
  DELETE_SITE,
  FETCH_SITE,
  UPDATE_SITE,
} from './siteActions.type';
import { FETCH_SITE_SUCCESS } from './siteMutations.type';

export default {
  state: {
    siteData: {},
  },
  getters: {
    siteData(state) {
      return state.siteData;
    },
  },
  mutations: {
    [FETCH_SITE_SUCCESS](state, data) {
      Vue.set(state, 'siteData', data.site);
    },
  },
  actions: {
    [FETCH_SITE](context, id) {
      return new Promise((resolve, reject) => {
        SitesService.get(id)
          .then((response) => {
            context.commit(FETCH_SITE_SUCCESS, response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [CHECK_SITE](context, params) {
      return new Promise((resolve, reject) => {
        SitesService.checkSite(params)
          .then((response) => {
            context.dispatch(FINISHED_LOADING);
            resolve(response.data);
          })
          .catch((error) => {
            context.dispatch(FINISHED_LOADING);
            reject(error);
          });
      });
    },
    [CREATE_SITE](context, params) {
      return new Promise((resolve, reject) => {
        // do the actual API call
        SitesService.create(params)
          .then((response) => {
            context.dispatch(FINISHED_LOADING);
            resolve(response.data);
          })
          .catch((error) => {
            context.dispatch(FINISHED_LOADING);
            reject(error);
          });
      });
    },
    [CREATE_ADMIN_SITE](context, params) {
      return new Promise((resolve, reject) => {
        context.dispatch(IS_LOADING).then(() => {
          // do the actual API call
          SitesService.createAdminSite(params.formData)
            .then((response) => {
              context.dispatch(FINISHED_LOADING);
              resolve(response.data);
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING);
              reject(error);
            });
        });
      });
    },
    [UPDATE_SITE](context, params) {
      return new Promise((resolve, reject) => {
        context.dispatch(IS_LOADING).then(() => {
          // do the actual API call
          SitesService.update(params.id, params.formData)
            .then((response) => {
              context.dispatch(FINISHED_LOADING);
              resolve(response.data);
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING);
              reject(error);
            });
        });
      });
    },
    [DELETE_SITE](context, payload) {
      return new Promise((resolve, reject) => {
        context.dispatch(IS_LOADING).then(() => {
          SitesService.destroy(payload)
            .then((response) => {
              setTimeout(() => {
                context.dispatch(FINISHED_LOADING);
                resolve(response.data);
              }, 500);
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING);
              reject(error);
            });
        });
      });
    },
  },
};
