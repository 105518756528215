export const FETCH_UNLISTED_SITES = 'fetchUnlistedSites';
export const UPDATE_ACTIVE_SORT_FIELD_UNLISTED_SITES = 'updateActiveSortFieldUnlistedSites';
export const UPDATE_SEARCH_UNLISTED_SITES = 'updateSearchUnlistedSites';
export const CLEAR_SEARCH_UNLISTED_SITES = 'clearSearchUnlistedSites';
export const CHECK_UNLISTED_SITE = 'checkUnlistedSite';
export const CREATE_UNLISTED_SITE = 'createUnlistedSite';
export const UPDATE_UNLISTED_SITE = 'updateUnlistedSite';
export const DELETE_UNLISTED_SITE = 'deleteUnlistedSite';
export const REJECT_UNLISTED_SITE = 'rejectUnlistedSite';
export const MARK_UNLISTED_SITE_LIKE_OK = 'markUnlistedOk';
export const UNFETCHD_UNLISTED_SITES_BY_TOKEN = 'unfetchUnlistedSitesByToken';
