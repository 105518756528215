export const FETCH_FAV_PAGES = 'fetchFavPages';
export const UPDATE_ACTIVE_SORT_FIELD_FAV_PAGES = 'updateActiveSortFieldFavPages';
export const UPDATE_SEARCH_FAV_PAGES = 'updateSearchFavPages';
export const CLEAR_SEARCH_FAV_PAGES = 'clearSearchFavPages';
export const ADMIN_CREATE_FAV_PAGE = 'ADMIN_CREATE_FAV_PAGE';
export const ADMIN_UPDATE_FAV_PAGE = 'ADMIN_UPDATE_FAV_PAGE';
export const ADMIN_DELETE_FAV_PAGE = 'ADMIN_DELETE_FAV_PAGE';
export const ADMIN_UPDATE_FAV_LINE_POSITION = 'ADMIN_UPDATE_FAV_LINE_POSITION';
export const ADMIN_DELETE_FAV_CELL_FOR_FAVLINE = 'ADMIN_DELETE_FAV_CELL_For_FAVLINE';
export const ADMIN_UPDATE_FAV_CELL_POSITION_IN_FAV_LINE = 'ADMIN_UPDATE_FAV_CELL_POSITION_IN_FAV_LINE';
