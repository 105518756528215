/* eslint-disable import/no-cycle */
import Vue from 'vue';

const MODULE_ROUTE = 'admin/dashboard';

export default {
  get() {
    return Vue.axios.get(`${MODULE_ROUTE}`).catch(error => Promise.reject(error));
  },
};
