/* eslint-disable import/no-cycle,no-shadow */
import LocalStorageService from '@/services/localStorage.service';
import EmailService from '@/services/emails.service';
import {
  SEND_CONTACT_FORM,
} from '@/store/modules/emails/emailActions.type';

const state = {
  user: LocalStorageService.getUser(),
};


const actions = {
  [SEND_CONTACT_FORM](context, payload) {
    const contactFormRoute = 'contact/sendForm';
    return new Promise((resolve, reject) => {
      EmailService.postContactForm(contactFormRoute, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
};


export default {
  state,
  actions,
};
