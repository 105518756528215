/* eslint-disable import/no-cycle */
import Vue from 'vue';

const MODULE_ROUTE = 'user-tabs';

export default {
  query(params) {
    return Vue.axios.get(MODULE_ROUTE, { params }).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  get(id) {
    return Vue.axios.get(`${MODULE_ROUTE}/${id}`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  create(data) {
    return Vue.axios.post(MODULE_ROUTE, { userTab: data }).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  update(data) {
    return Vue.axios.put(`${MODULE_ROUTE}/${data.id}`, { userTab: data }).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  destroy(id) {
    return Vue.axios.delete(`${MODULE_ROUTE}/${id}`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
};
