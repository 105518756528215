import { FETCH_ADMIN_DASHBOARD } from '@/store/modules/admin/resources/adminDashboard/adminDashboardActions.type';
import AdminDashboardService from '@/services/adminDashboard.service';
import { FINISHED_LOADING, IS_LOADING } from '@/store/modules/layout/layoutActions.type';

export default {
  actions: {
    [FETCH_ADMIN_DASHBOARD](context) {
      return new Promise((resolve, reject) => {
        context.dispatch(IS_LOADING).then(() => {
          AdminDashboardService.get()
            .then((response) => {
              context.dispatch(FINISHED_LOADING).then(() => {
                resolve(response.data);
              });
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING).then(() => {
                reject(error);
              });
            });
        });
      });
    },
  },
};
