<template>
  <div id="footer" class="md-layout md-alignment-center-center">
    <div class="md-layout-item md-size-100 md-xsmall-size-100">
      <div id="rec-footer-top" class="md-layout md-alignment-center-top" v-if="!isMobile">
        <div class="md-layout-item md-size-50 md-xsmall-size-50">
          <div class="footer-menu">
            <div class="footer-link join-sentence">
              Join thousands of people who organize their <br> bookmarks and life with All My Faves
            </div>
            <div class="footer-link get-started-sentence">
              <router-link class="get-started" tag="a" :to="{ name: 'userLogin' }">
                Get Started,
              </router-link>
              it’s free
            </div>
            <div id="social-menu">
              <a href="https://www.twitter.com/allmyfaves" class="md-flat md-icon-button md-button"
                style="padding-top:7px;" target="_blank">
                <md-icon>
                  <i class="fab fa-twitter fab-2xl"></i>
                </md-icon>
              </a>
              <a href="http://www.facebook.com/allmyfaves" class="md-flat md-icon-button md-button"
                style="padding-top:7px;" target="_blank">
                <md-icon>
                  <i class="fab fa-facebook-square"></i>
                </md-icon>
              </a>
            </div>
          </div>
        </div>
        <div class="footer-links-container md-layout-item md-size-50 md-xsmall-size-50">
          <div class="md-layout-item md-size-33 md-xsmall-size-33">
            <div class="footer-menu">
              <h3 class="footer-section-title">{{ $t('footer.wantMore') }}</h3>
              <div class="footer-link" v-if="!isNotLogged">
                <router-link tag="a" :to="{ name: 'billing' }">
                  Upgrade your plan <span class="upgrade-plan"> NEW </span>
                </router-link>
              </div>
              <div class="footer-link">
                <router-link tag="a" :to="{ name: 'TYPE_CATEGORY', params: { categoryName: 'blogs' } }">
                  Blogs
                </router-link>
              </div>
              <div class="footer-link">
                <router-link tag="a" :to="{ name: 'TYPE_CATEGORY', params: { categoryName: 'business' } }">
                  Business
                </router-link>
              </div>
              <div class="footer-link">
                <router-link tag="a" :to="{ name: 'TYPE_CATEGORY', params: { categoryName: 'edu' } }">
                  Education
                </router-link>
              </div>
              <div class="footer-link">
                <router-link tag="a" :to="{ name: 'TYPE_CATEGORY', params: { categoryName: 'entertainment' } }">
                  Entertainment
                </router-link>
              </div>
              <div class="footer-link">
                <router-link tag="a" :to="{ name: 'TYPE_CATEGORY', params: { categoryName: 'games' } }">
                  Games
                </router-link>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-33 md-xsmall-size-33">
            <div class="footer-menu">
              <h3 class="footer-section-title"> {{ $t('footer.titleSection1') }}</h3>
              <div class="footer-link">
                <router-link tag="a" :to="{ name: 'aboutUs' }">
                  {{ $t('footer.aboutUs') }}
                </router-link>
              </div>
              <!--
            <div class="footer-link">
              <router-link tag="a" :to="{name: 'home'}">
                {{ $t('footer.tellAFriend') }}
              </router-link>
            </div>
            <div class="footer-link">
              <router-link tag="a" :to="{name: 'press'}">
                {{ $t('footer.press') }}
              </router-link>
            </div>
          -->
              <div class="footer-link">
                <a href="https://blog.allmyfaves.com" target="_blank">
                  {{ $t('footer.blog') }}
                </a>
              </div>
              <div class="footer-link">
                <a href="https://old.allmyfaves.com" target="_blank">
                  {{ $t('footer.oldWebsite') }}
                </a>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-33 md-xsmall-size-33">
            <div class="footer-menu">
              <h3 class="footer-section-title">{{ $t('footer.support') }}</h3>
              <div class="footer-link">
                <router-link tag="a" :to="{ name: 'contact' }">
                  {{ $t('footer.contactUs') }}
                </router-link>
              </div>
              <div class="footer-link">
                <router-link tag="a" :to="{ name: 'faq' }">
                  {{ $t('footer.faq') }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="rec-footer-bottom" class="md-layout md-alignment-center-center" v-if="!isMobile">
        <div class="md-layout-item md-alignment-center-center">
          <div class="logo-footer-container">
            <img id="logo" :src="`${publicPath}AMF_small_logo.png`" alt="AMF footer logo" @click="home">
          </div>
        </div>
        <div class="md-layout-item md-alignment-center-center">
          <div id="privacy-menu">
            <router-link tag="a" :to="{ name: 'privacy' }">
              {{ $t('footer.privacy') }}
            </router-link>
            <span class="separator">|</span>
            <router-link tag="a" :to="{ name: 'termsConditions' }">
              {{ $t('footer.terms') }}
            </router-link>
          </div>
          <div class="copyright-container">
            &copy;
            <router-link tag="a" :to="{ name: 'home' }">
              AllMyFaves
            </router-link>
            . {{ $t('footer.rights') }}
          </div>
        </div>
      </div>

      <div id="rec-footer-top" class="md-layout md-alignment-center" v-if="isMobile">
        <div class="md-layout-item  md-alignment-center-center">
          <div class="footer-menu">
            <div class="footer-link join-sentence">
              Join thousands of people who organize their bookmarks and life with All My Faves
            </div>
            <div class="footer-link get-started-sentence">
              <router-link class="get-started" tag="a" :to="{ name: 'userLogin' }">
                Get Started,
              </router-link>
              it’s free
            </div>
            <div id="social-menu">
              <a href="https://www.twitter.com/allmyfaves" class="md-flat md-icon-button md-button"
                style="padding-top:7px;" target="_blank">
                <md-icon>
                  <i class="fab fa-twitter fab-2xl"></i>
                </md-icon>
              </a>
              <a href="http://www.facebook.com/allmyfaves" class="md-flat md-icon-button md-button"
                style="padding-top:7px;" target="_blank">
                <md-icon>
                  <i class="fab fa-facebook-square"></i>
                </md-icon>
              </a>
            </div>
          </div>
        </div>
        <div class="footer-list-container">
          <div class="md-layout-item  md-alignment-center-center">
          <div class="footer-menu">
            <h3 class="footer-section-title">{{ $t('footer.wantMore') }}</h3>
            <div class="footer-collapse-menu">
              <div class="footer-link" v-if="!isNotLogged">
                <router-link tag="a" :to="{ name: 'billing' }">
                  Upgrade your plan <span class="upgrade-plan"> NEW </span>
                </router-link>
              </div>
              <div class="footer-link">
                <router-link tag="a" :to="{ name: 'TYPE_CATEGORY', params: { categoryName: 'blogs' } }">
                  Blogs
                </router-link>
              </div>
              <div class="footer-link">
                <router-link tag="a" :to="{ name: 'TYPE_CATEGORY', params: { categoryName: 'business' } }">
                  Business
                </router-link>
              </div>
              <div class="footer-link">
                <router-link tag="a" :to="{ name: 'TYPE_CATEGORY', params: { categoryName: 'education' } }">
                  Education
                </router-link>
              </div>
              <div class="footer-link">
                <router-link tag="a" :to="{ name: 'TYPE_CATEGORY', params: { categoryName: 'entertainment' } }">
                  Entertainment
                </router-link>
              </div>
              <div class="footer-link">
                <router-link tag="a" :to="{ name: 'TYPE_CATEGORY', params: { categoryName: 'games' } }">
                  Games
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="md-layout-item  md-alignment-center-center">
          <div class="footer-menu">
            <h3 class="footer-section-title"> {{ $t('footer.titleSection1') }}</h3>
            <div class="footer-collapse-menu">
              <div class="footer-link">
                <router-link tag="a" :to="{ name: 'aboutUs' }">
                  {{ $t('footer.aboutUs') }}
                </router-link>
              </div>
              <div class="footer-link">
                <a href="https://blog.allmyfaves.com" target="_blank">
                  {{ $t('footer.blog') }}
                </a>
              </div>
              <div class="footer-link">
                <a href="https://old.allmyfaves.com" target="_blank">
                  {{ $t('footer.oldWebsite') }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="md-layout-item  md-alignment-center-center">
          <div class="footer-menu">
            <h3 class="footer-section-title">{{ $t('footer.support') }}</h3>
            <div class="footer-link">
              <router-link tag="a" :to="{ name: 'contact' }">
                {{ $t('footer.contactUs') }}
              </router-link>
            </div>
            <div class="footer-link">
              <router-link tag="a" :to="{ name: 'faq' }">
                {{ $t('footer.faq') }}
              </router-link>
            </div>
          </div>
        </div>
        </div>
      </div>

      <div id="rec-footer-bottom" class="md-layout md-alignment-center-center" v-if="isMobile">
        <div class="md-layout-item  md-alignment-center-center">
          <div class="logo-footer-container">
            <img id="logo" :src="`${publicPath}AMF_small_logo.png`" alt="AMF footer logo" @click="home">
          </div>
        </div>
        <div class="md-layout-item  md-alignment-center-center">
          <div id="privacy-menu">
            <router-link tag="a" :to="{ name: 'privacy' }">
              {{ $t('footer.privacy') }}
            </router-link>
            <span class="separator">|</span>
            <router-link tag="a" :to="{ name: 'termsConditions' }">
              {{ $t('footer.terms') }}
            </router-link>
          </div>
          <div class="copyright-container">
            &copy;
            <router-link tag="a" :to="{ name: 'home' }">
              AllMyFaves
            </router-link>
            . {{ $t('footer.rights') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import smallDevicesCheckMixin from '@/mixins/smallDevicesCheckMixin';

export default {
  name: 'RecFooter',
  mixins: [
    smallDevicesCheckMixin,
  ],
  mounted() {
    this.checkDevice();
    this.smallDeviceCheck();
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isNotLogged() {
      return this.$store.getters.user === null;
    },
  },
  methods: {
    home() {
      if (this.$route.path !== '/') {
        this.$router.push('/');
      }
    },
  },
  data: () => ({
    publicPath: process.env.BASE_URL,
    windowWidth: null,
    isMobile: false,
  }),
};
</script>

<style lang="scss">
@import '@/assets/scss/layout/rec-footer.scss';
</style>
