/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { API_URL } from '@/config';
import LocalStorageService from '@/services/localStorage.service';
import router from '@/router';
import store from '@/store';
import { PURGE_AUTH } from '@/store/modules/auth/authMutations.type';

// Add a request interceptor
axios.interceptors.request.use(
  (cfg) => {
    console.log('REQUEST INTERCEPTOR SUCCESS');
    if (LocalStorageService.getToken()) {
      // TODO do not remove this line and add metrics/tracking for api with slack client
      // console.log('REQUEST INTERCEPTOR SUCCESS NO TOKEN', cfg);
      const token = LocalStorageService.getToken();
      cfg.headers.Authorization = `Bearer ${token}`;
      // TODO do not remove this line and add metrics/tracking for api with slack client
      // console.log('REQUEST INTERCEPTOR SUCCESS WITH TOKEN', cfg);
    }
    return cfg;
  },
  (err) => {
    // TODO do not remove this line and add metrics/tracking for api with slack client
    // eslint-disable-next-line no-console
    console.log('REQUEST INTERCEPTOR ERROR');
    return Promise.reject(err.response.data.message);
  },
);

// Add a response interceptor
axios.interceptors.response.use(
  (res) => {
    // TODO do not remove this line and add metrics/tracking for api with slack client
    // eslint-disable-next-line no-console
    console.log('RESPONSE INTERCEPTOR SUCCESS');
    return res;
  },
  (err) => {
    // TODO do not remove this line and add metrics/tracking for api with slack client
    // eslint-disable-next-line no-console
    // console.log('RESPONSE INTERCEPTOR ERROR', err);
    // NOT LOGGED IN
    if (err.response.status === 401) {
      // console.log('RESPONSE STATUS :: 401');
      store.commit(PURGE_AUTH);
    }

    // NOT ENOUGH PERMISSIONS
    if (err.response.status === 403) {
      // console.log('RESPONSE STATUS :: 403');
      router.push({ name: 'home' });
    }

    // TODO add back this if needed
    // if (err.response.status === 404) {
    //   console.log('RESPONSE STATUS :: 404');
    //   router.push({ name: 'not-found' });
    // }

    if (err.response.status === 422) {
      // console.log('RESPONSE STATUS :: 422');
      // console.log(err.response.data);
      return Promise.reject(err.response.data.errors);
    }

    return Promise.reject(err.response.data.message);
  },
);

export default {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
  },
  get(url) {
    return Vue.axios.get(`${url}`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  socialLogin(resource) {
    return Vue.axios.get(`${resource}`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  socialLoginSuccess(resource) {
    return Vue.axios.get(`${resource}?`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
};
