export const SIDEBAR_TOGGLE_M = 'toggleSidebar';
export const SIDEBAR_CLOSE_M = 'closeSidebar';

export const IS_LOADING_M = 'isLoading';
export const FINISHED_LOADING_M = 'finishedLoading';

export const SET_LANGUAGE_APP_M = 'setLanguageAppMutation';
export const SET_TOUR_M = 'setTourMutation';

export const SAVE_CELL_LOADING_M = 'saveCellLoading';
export const FINISH_SAVE_CELL_LOADING_M = 'finishSaveCellLoading';
export const CHANGE_CELL_LOADING_M = 'changeCellLoading';
export const FINISH_CHANGE_CELL_LOADING_M = 'finishChangeCellLoading';
