export default {
  data: () => ({
    LINES_LIMIT_FOR_TOP_AD: 18,
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isPaidUser() {
      return this.user !== null && this.user.plan_id != null;
    },
    isPremiumUser() {
      return this.user !== null && this.user.premium != null;
    },
    isAdminUser() {
      return this.user !== null && this.user.role === 'admin';
    },
    hasSubscription() {
      return this.isPaidUser || this.isPremiumUser || this.isAdminUser;
    },
    HasNotAdsSection() {
      // check null because homepage doesn't have name
      return (this.$route.name) ? (this.$route.name.includes('user')
        || this.$route.name.includes('admin')
        || this.$route.name.includes('aboutUs')
        || this.$route.path.includes('user-settings')
        || this.$route.path.includes('bookmarklet')
        || this.$route.name.includes('privacy')
        || this.$route.name.includes('step1Login')
        || this.$route.name.includes('step2Login')
        || this.$route.name.includes('mostPopularSites')
        || this.$route.name.includes('termsConditions')) : true;
    },
  },
};
