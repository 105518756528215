/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import SitesService from '@/services/sites.service';
import {
  FETCH_SITES,
  UPDATE_ACTIVE_SORT_FIELD_SITES,
  UPDATE_SEARCH_SITES,
  CLEAR_SEARCH_SITES,
  TOGGLE_NOT_LINKED_FILTER,
} from './sitesActions.type';
import {
  FETCH_SITES_SUCCESS,
  UPDATE_ACTIVE_SORT_FIELD_SITES_M,
  UPDATE_SEARCH_SITES_M,
  CLEAR_SEARCH_SITES_M,
  TOGGLE_NOT_LINKED_FILTER_M,
} from './sitesMutations.type';

export default {
  state: {
    data: [],
    perPage: 25,
    currentPage: 1,
    lastPage: 1,
    from: 1,
    to: 10,
    total: 10,
    sort: [
      {
        name: 'ID ASC',
        key: 'id',
        dir: 'ASC',
        code: 'id_ASC',
        active: true,
      },
      {
        name: 'ID DESC',
        key: 'id',
        dir: 'DESC',
        code: 'id_DESC',
        active: false,
      },
      {
        name: 'Newest',
        key: 'created_at',
        dir: 'DESC',
        code: 'created_at_DESC',
        active: false,
      },
      {
        name: 'Oldest',
        key: 'created_at',
        dir: 'ASC',
        code: 'created_at_ASC',
        active: false,
      },
      {
        name: 'A-Z by address',
        key: 'address',
        dir: 'ASC',
        code: 'address_ASC',
        active: false,
      },
      {
        name: 'Z-A by address',
        key: 'address',
        dir: 'DESC',
        code: 'address_DESC',
        active: false,
      },
      {
        name: 'Tab Cells Increasing',
        key: 'tab_cells_count',
        dir: 'ASC',
        code: 'tab_cells_count_ASC',
        active: false,
      },
      {
        name: 'Tab Cells Decreasing',
        key: 'tab_cells_count',
        dir: 'DESC',
        code: 'tab_cells_count_DESC',
        active: false,
      },
      {
        name: 'Favorite Site Decreasing',
        key: 'favorite_site',
        dir: 'DESC',
        code: 'favorite_site_DESC',
        active: false,
      },
    ],
    search: '',
    notLinkedFilter: false,
  },
  getters: {
    sitesItems(state) {
      return state.data;
    },
    sitesPerPage(state) {
      return state.perPage;
    },
    sitesCurrentPage(state) {
      return state.currentPage;
    },
    sitesLastPage(state) {
      return state.lastPage;
    },
    sitesFrom(state) {
      return state.from;
    },
    sitesTo(state) {
      return state.to;
    },
    sitesTotal(state) {
      return state.total;
    },
    sitesSortFields(state) {
      return state.sort;
    },
    sitesActiveSort(state) {
      const activeSorts = state.sort.filter(sort => sort.active === true);
      return activeSorts[0];
    },
    sitesSearch(state) {
      return state.search;
    },
    notLinkedFilter(state) {
      return state.notLinkedFilter;
    },
  },
  mutations: {
    [FETCH_SITES_SUCCESS](state, data) {
      state.data = data.data;
      state.perPage = parseInt(data.perPage, 10);
      state.currentPage = data.currentPage;
      state.lastPage = data.lastPage;
      state.from = data.from;
      state.to = data.to;
      state.total = data.total;
    },
    [UPDATE_ACTIVE_SORT_FIELD_SITES_M](state, data) {
      const newSortFields = [];
      state.sort.forEach((element) => {
        element.active = false;
        if (element.code === data) {
          element.active = true;
        }
        newSortFields.push(element);
      });

      Vue.set(state, 'sort', newSortFields);
    },
    [UPDATE_SEARCH_SITES_M](state, data) {
      Vue.set(state, 'search', data);
    },
    [CLEAR_SEARCH_SITES_M](state) {
      Vue.set(state, 'search', '');
    },
    [TOGGLE_NOT_LINKED_FILTER_M](state) {
      Vue.set(state, 'notLinkedFilter', !state.notLinkedFilter);
    },
  },
  actions: {
    [FETCH_SITES](context, params) {
      return new Promise((resolve, reject) => {
        // check search
        if (context.state.search && context.state.search !== '') {
          params.search = context.state.search;
        }
        // check active sort
        const activeSortArr = context.state.sort.filter(sort => sort.active === true);
        if (activeSortArr.length > 0) {
          params.sortBy = activeSortArr[0].key;
          params.sortDir = activeSortArr[0].dir;
        }

        // check notLinkedFilter
        const { notLinkedFilter } = context.state;
        if (notLinkedFilter) {
          params.notLinkedFilter = true;
        }

        // do the actual API call
        SitesService.query(params)
          .then((response) => {
            context.commit(FETCH_SITES_SUCCESS, response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [UPDATE_ACTIVE_SORT_FIELD_SITES](context, params) {
      context.commit(UPDATE_ACTIVE_SORT_FIELD_SITES_M, params);
    },
    [UPDATE_SEARCH_SITES](context, params) {
      context.commit(UPDATE_SEARCH_SITES_M, params);
    },
    [CLEAR_SEARCH_SITES](context) {
      context.commit(CLEAR_SEARCH_SITES_M);
    },
    [TOGGLE_NOT_LINKED_FILTER](context) {
      context.commit(TOGGLE_NOT_LINKED_FILTER_M);
      context.dispatch(FETCH_SITES, {
        page: 1,
        perPage: context.state.perPage,
      }).then();
    },
  },
};
