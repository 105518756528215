import Vue from 'vue';
import Toasted from 'vue-toasted';
import NProgress from 'vue-nprogress';
import VueLogger from 'vuejs-logger';
import '@/plugins';
import VueTour from 'vue-tour';
import i18n from '@/plugins/i18n';
import ApiService from '@/services/api.service';
import Ads from 'vue-google-adsense';
import store from './store';
import router from './router';
import App from './App.vue';

Vue.use(require('vue-script2'));

Vue.use(Ads.Adsense);
Vue.use(Ads.InArticleAdsense);
Vue.use(Ads.InFeedAdsense);

Vue.use(Toasted);
Vue.use(VueTour);
require('vue-tour/dist/vue-tour.css');
require('@/assets/scss/tour-steps.scss');

const progressOptions = {
  latencyThreshold: 100, // Number of ms before progressbar starts showing, default: 100,
  router: true, // Show progressbar when navigating routes, default: true
  http: true, // Show progressbar when doing Vue.http, default: true
};
Vue.use(NProgress, progressOptions);
// APIs: see https://github.com/rstacruz/nprogress
// app.nprogress
// app.nprogress.start()
// app.nprogress.inc(0.2)
// app.nprogress.done()
// Component:
// this.$nprogress

const nprogress = new NProgress({ parent: '.nprogress-container' });

const isProduction = process.env.NODE_ENV === 'production';

const loggerOptions = {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
};


Vue.use(VueLogger, loggerOptions);

Vue.config.productionTip = false;

ApiService.init();

new Vue({
  nprogress,
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
