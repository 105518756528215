/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import MostPopularSiteService from '@/services/mostPopularSites.service';
import { FETCH_MOST_POPULAR_SITE, UPDATE_MOST_POPULAR_SITE } from '@/store/actions.type';
import {
  FETCH_MOST_POPULAR_SITE_SUCCESS,
} from '../../../../mutations.type';

export default {
  state: {
    siteData: {},
  },
  getters: {
    mostPopularSiteData(state) {
      return state.mostPopularSiteData;
    },
  },
  mutations: {
    [FETCH_MOST_POPULAR_SITE_SUCCESS](state, data) {
      Vue.set(state, 'mostPopularSiteData', data.site);
    },
  },
  actions: {
    [FETCH_MOST_POPULAR_SITE](context, id) {
      return new Promise((resolve, reject) => {
        MostPopularSiteService.get(id)
          .then((response) => {
            context.commit(FETCH_MOST_POPULAR_SITE_SUCCESS, response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [UPDATE_MOST_POPULAR_SITE](context, payload) {
      return new Promise((resolve, reject) => {
        MostPopularSiteService.update(payload.id, payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
