/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import FavCellsService from '@/services/tabCells.service';
import {
  FETCH_TAB_CELLS,
  UPDATE_ACTIVE_SORT_FIELD_TAB_CELLS,
  UPDATE_SEARCH_TAB_CELLS,
  CLEAR_SEARCH_TAB_CELLS,
} from './tabCellsActions.type';
import {
  FETCH_TAB_CELLS_SUCCESS,
  UPDATE_ACTIVE_SORT_FIELD_TAB_CELLS_M,
  UPDATE_SEARCH_TAB_CELLS_M,
  CLEAR_SEARCH_TAB_CELLS_M,
} from './tabCellsMutations.type';

export default {
  state: {
    data: [],
    perPage: 25,
    currentPage: 1,
    lastPage: 1,
    from: 1,
    to: 10,
    total: 10,
    sort: [
      {
        name: 'ID ASC',
        key: 'id',
        dir: 'ASC',
        code: 'id_ASC',
        active: true,
      },
      {
        name: 'ID DESC',
        key: 'id',
        dir: 'DESC',
        code: 'id_DESC',
        active: false,
      },
      {
        name: 'A-Z by key',
        key: 'key',
        dir: 'ASC',
        code: 'key_ASC',
        active: false,
      },
      {
        name: 'Z-A by key',
        key: 'key',
        dir: 'DESC',
        code: 'key_DESC',
        active: false,
      },
      {
        name: 'A-Z by domain',
        key: 'domain',
        dir: 'ASC',
        code: 'domain_ASC',
        active: false,
      },
      {
        name: 'Z-A by domain',
        key: 'domain',
        dir: 'DESC',
        code: 'domain_DESC',
        active: false,
      },
      {
        name: 'A-Z by url',
        key: 'url',
        dir: 'ASC',
        code: 'url_ASC',
        active: false,
      },
      {
        name: 'Z-A by url',
        key: 'url',
        dir: 'DESC',
        code: 'url_DESC',
        active: false,
      },
    ],
    search: '',
  },
  getters: {
    tabCellsItems(state) {
      return state.data;
    },
    tabCellsPerPage(state) {
      return state.perPage;
    },
    tabCellsCurrentPage(state) {
      return state.currentPage;
    },
    tabCellsLastPage(state) {
      return state.lastPage;
    },
    tabCellsFrom(state) {
      return state.from;
    },
    tabCellsTo(state) {
      return state.to;
    },
    tabCellsTotal(state) {
      return state.total;
    },
    tabCellsSortFields(state) {
      return state.sort;
    },
    tabCellsActiveSort(state) {
      const activeSorts = state.sort.filter(sort => sort.active === true);
      return activeSorts[0];
    },
    tabCellsSearch(state) {
      return state.search;
    },
  },
  mutations: {
    [FETCH_TAB_CELLS_SUCCESS](state, data) {
      state.data = data.data;
      state.perPage = parseInt(data.perPage, 10);
      state.currentPage = data.currentPage;
      state.lastPage = data.lastPage;
      state.from = data.from;
      state.to = data.to;
      state.total = data.total;
    },
    [UPDATE_ACTIVE_SORT_FIELD_TAB_CELLS_M](state, data) {
      const newSortFields = [];
      state.sort.forEach((element) => {
        element.active = false;
        if (element.code === data) {
          element.active = true;
        }
        newSortFields.push(element);
      });

      Vue.set(state, 'sort', newSortFields);
    },
    [UPDATE_SEARCH_TAB_CELLS_M](state, data) {
      Vue.set(state, 'search', data);
    },
    [CLEAR_SEARCH_TAB_CELLS_M](state) {
      Vue.set(state, 'search', '');
    },
  },
  actions: {
    [FETCH_TAB_CELLS](context, params) {
      return new Promise((resolve, reject) => {
        // check search
        if (context.state.search && context.state.search !== '') {
          params.search = context.state.search;
        }
        // check active sort
        const activeSortArr = context.state.sort.filter(sort => sort.active === true);
        if (activeSortArr.length > 0) {
          params.sortBy = activeSortArr[0].key;
          params.sortDir = activeSortArr[0].dir;
        }

        // do the actual API call
        FavCellsService.query(params)
          .then((response) => {
            context.commit(FETCH_TAB_CELLS_SUCCESS, response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    [UPDATE_ACTIVE_SORT_FIELD_TAB_CELLS](context, params) {
      context.commit(UPDATE_ACTIVE_SORT_FIELD_TAB_CELLS_M, params);
    },
    [UPDATE_SEARCH_TAB_CELLS](context, params) {
      context.commit(UPDATE_SEARCH_TAB_CELLS_M, params);
    },
    [CLEAR_SEARCH_TAB_CELLS](context) {
      context.commit(CLEAR_SEARCH_TAB_CELLS_M);
    },
  },
};
