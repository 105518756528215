/* eslint-disable import/no-cycle */
import Vue from 'vue';

const MODULE_ROUTE = 'fav-lines';

export default {
  query(params) {
    return Vue.axios.get(MODULE_ROUTE, { params }).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  get(id) {
    return Vue.axios.get(`${MODULE_ROUTE}/${id}`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  getFavCellsForFavLine(id) {
    return Vue.axios.get(`${MODULE_ROUTE}/${id}/fav-cells`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  create(data) {
    return Vue.axios.post(MODULE_ROUTE, { favLine: data }).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  update(data) {
    return Vue.axios.put(`${MODULE_ROUTE}/${data.id}`, { favLine: data }).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  updatePosition(data) {
    return Vue.axios.put(`/fav-pages/${data.pageId}/${MODULE_ROUTE}/${data.id}/position`, data).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  destroy(id) {
    return Vue.axios.delete(`${MODULE_ROUTE}/${id}`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
};
