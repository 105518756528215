/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import TabCellsService from '@/services/tabCells.service';
import { FETCH_TAB_CELL } from './tabCellActions.type';
import { FETCH_TAB_CELL_SUCCESS } from './tabCellMutations.type';

export default {
  state: {
    tabCellData: {},
  },
  getters: {
    tabCellData(state) {
      return state.tabCellData;
    },
  },
  mutations: {
    [FETCH_TAB_CELL_SUCCESS](state, data) {
      Vue.set(state, 'tabCellData', data.tabCell);
    },
  },
  actions: {
    [FETCH_TAB_CELL](context, id) {
      return new Promise((resolve, reject) => {
        TabCellsService.get(id)
          .then((response) => {
            context.commit(FETCH_TAB_CELL_SUCCESS, response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
