<template>
  <div>
    <md-dialog :md-active.sync="isShareTabModalVisible" class="md-dialog-tab"
            @keydown.esc="close">
      <form>
        <md-dialog-title class="modal-header"> Share Tab
          <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close modal"
          >
          <span class="material-icons">close</span>
          </button>
        </md-dialog-title>

        <div v-if="!isPrivate">
        <md-dialog-content class="dialog-content">
          <md-content class="shared-modal-body modal-input form-control input-with-border">
            <input class="link-input"
                  disabled
                   id="name"
                   type="text"
                   placeholder="Link"
                   v-model="generatedShareURL"
            />
            <i class="copy-icon fas fa-copy" @click="copyURL(generatedShareURL)"
            title="copy link"></i>
          </md-content>
        </md-dialog-content>
        <md-dialog-actions class="modal-footer">
          <div class="md-layout md-buttons-modal shared-tab-actions">
                <md-button class="share-btn twitter" title="Twitter" @click="shareTabLink('twitter')">
                  Twitter
                </md-button>
                <md-button class="share-btn facebook" title="Facebook" @click="shareTabLink('facebook')">
                  Facebook
                </md-button>
                <md-button class="share-btn linkedin" title="LinkedIn" @click="shareTabLink('linkedin')">
                  LinkedIn
                </md-button>
                <md-button class="share-btn email" title="Email" @click="shareTabLink('email')">
                  Email
                </md-button>
              <br>
          </div>
        </md-dialog-actions>
      </div>

      <md-dialog-content v-else class="dialog-content">
          <md-dialog-actions class="modal-footer">
            This tab is private. You can't share it.
          </md-dialog-actions>
        </md-dialog-content>

      </form>
    </md-dialog>
  </div>
</template>

<script>
export default {
  name: 'ShareTabModal',
  props: {
    isShareTabModalVisible: Boolean,
    sharedTabObject: Object,
  },
  data: () => ({
    publicPath: process.env.BASE_URL,
    generatedShareURL: null,
  }),
  computed: {
    isPrivate() {
      return this.sharedTabObject && this.sharedTabObject.privatetab;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    shareTab() {
      const baseUrl = window.location.origin;
      const username = this.$route.name === 'home' ? this.$store.getters.user?.username : this.$store.getters.userData?.username;
      const generatedURL = `${baseUrl}/${username}?tab=${encodeURIComponent(this.sharedTabObject?.name)}`;
      this.generatedShareURL = generatedURL;
    },
    async copyURL(link) {
      try {
        await navigator.clipboard.writeText(link);
        this.$toasted.show('The tab link has been copied! You can now paste it anywhere you like!', {
          type: 'success',
          duration: 5000,
          icon: 'check',
        });
      } catch ($e) {
        this.$toasted.error('The tab link hasn\'t been copied!', {
          type: 'error',
          duration: 5000,
          icon: 'alert',
        });
      }
    },
    shareTabLink(platform) {
      let url = '';
      const tabName = this.sharedTabObject.name;
      switch (platform) {
        case 'twitter':
          url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(this.generatedShareURL)}&text=${encodeURIComponent(`Check out my ${tabName} faves`)}&via=allmyfaves`;
          window.open(url, '_blank');
          break;
        case 'facebook':
          url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.generatedShareURL)}`;
          window.open(url, '_blank');
          break;
        case 'email':
          url = `mailto:?subject=${encodeURIComponent(`Check out my ${tabName} faves - ${this.generatedShareURL}`)}&body=${encodeURIComponent(this.generatedShareURL)}`;
          window.open(url, '_blank');
          break;
        case 'linkedin':
          url = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(this.generatedShareURL)}&title=${encodeURIComponent(`Check out my ${tabName} faves`)}`;
          window.open(url, '_blank');
          break;
        default:
      }
    },
  },
  watch: {
    isShareTabModalVisible: {
      handler(isShareTabModalVisible) {
        if (isShareTabModalVisible) {
          this.shareTab();
        }
      },
    },
  },
};
</script>


<style scoped>
.icon-space-right {
  margin-right: 15px;
}

.align-left {
  padding-left: 35px !important;
}

.margin-to-align {
  margin: 10px 0px 0px 10px;
}

.inline-width {
  width: 75%;
}

.share-btn {
  display: inline-block;
  color: #ffffff;
  border: none;
  padding: 0.1em 0.6em;
  outline: none;
  text-align: center;
  font-size: 0.9em;
  margin: 0 0.2em;
  border-radius: 8px;
}

.share-btn:focus,
.share-btn:hover {
  text-decoration: none;
  opacity: 0.8;
}

.share-btn:active {
  color: #e2e2e2;
}

.share-btn.twitter     { background: #55acee; }
.share-btn.facebook    { background: #3B5998; }
.share-btn.whatsapp  { background: #16a509; }
.share-btn.linkedin    { background: #4875B4; }
.share-btn.email       { background: #444444; }

.dialog-content {
  padding: 0px 24px;
}

.shared-modal-body {
  padding: 10px;
  margin: 20px 25px;
  display: flex;
}

.link-input{
  border: none;
  margin: auto;
  width: 100%;
  background-color: white;
}

.shared-tab-actions {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-footer {
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 850px) {
  .shared-tab-actions {
    display: contents;
  }
}

</style>
