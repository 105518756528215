/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import EditorTokenService from '@/services/editorTokens.service';
import { FINISHED_LOADING, IS_LOADING } from '@/store/modules/layout/layoutActions.type';
import {
  FETCH_EDITOR_TOKENS,
  UPDATE_ACTIVE_SORT_FIELD_TOKENS,
  UPDATE_SEARCH_TOKENS,
  CLEAR_SEARCH_TOKENS,
  DELETE_TOKEN,
} from './editorTokensActions.type';

import {
  FETCH_TOKENS_SUCCESS,
  UPDATE_ACTIVE_SORT_FIELD_TOKENS_M,
  UPDATE_SEARCH_TOKENS_M,
  CLEAR_SEARCH_TOKENS_M,
} from './editorTokensMutations.type';


export default {
  state: {
    data: [],
    perPage: 25,
    currentPage: 1,
    lastPage: 1,
    from: 1,
    to: 10,
    total: 10,
    sort: [
      {
        name: 'ID ASC',
        key: 'id',
        dir: 'ASC',
        code: 'id_ASC',
        active: true,
      },
      {
        name: 'ID DESC',
        key: 'id',
        dir: 'DESC',
        code: 'id_DESC',
        active: false,
      },
      {
        name: 'A-Z by Name',
        key: 'name',
        dir: 'ASC',
        code: 'name_ASC',
        active: false,
      },
      {
        name: 'Z-A by Name',
        key: 'name',
        dir: 'DESC',
        code: 'name_DESC',
        active: false,
      },
    ],
    search: '',
  },
  getters: {
    editorTokensItems(state) {
      return state.data;
    },
    editorTokensItemsPerPage(state) {
      return state.perPage;
    },
    editorTokensCurrentPage(state) {
      return state.currentPage;
    },
    editorTokensLastPage(state) {
      return state.lastPage;
    },
    editorTokensFrom(state) {
      return state.from;
    },
    editorTokensTo(state) {
      return state.to;
    },
    editorTokensTotal(state) {
      return state.total;
    },
    editorTokensSortFields(state) {
      return state.sort;
    },
    editorTokensActiveSort(state) {
      const activeSorts = state.sort.filter(sort => sort.active === true);
      return activeSorts[0];
    },
    editorTokensSearch(state) {
      return state.search;
    },
  },
  mutations: {
    [FETCH_TOKENS_SUCCESS](state, data) {
      state.data = data.data;
      state.perPage = parseInt(data.perPage, 10);
      state.currentPage = data.currentPage;
      state.lastPage = data.lastPage;
      state.from = data.from;
      state.to = data.to;
      state.total = data.total;
    },
    [UPDATE_ACTIVE_SORT_FIELD_TOKENS_M](state, data) {
      const newSortFields = [];
      state.sort.forEach((element) => {
        element.active = false;
        if (element.code === data) {
          element.active = true;
        }
        newSortFields.push(element);
      });

      Vue.set(state, 'sort', newSortFields);
    },
    [UPDATE_SEARCH_TOKENS_M](state, data) {
      Vue.set(state, 'search', data);
    },
    [CLEAR_SEARCH_TOKENS_M](state) {
      Vue.set(state, 'search', '');
    },
  },
  actions: {
    [FETCH_EDITOR_TOKENS](context, params) {
      return new Promise((resolve, reject) => {
        if (context.state.search && context.state.search !== '') {
          params.search = context.state.search;
        }
        const activeSortArr = context.state.sort.filter(sort => sort.active === true);
        if (activeSortArr.length > 0) {
          params.sortBy = activeSortArr[0].key;
          params.sortDir = activeSortArr[0].dir;
        }
        EditorTokenService.query(params)
          .then((response) => {
            context.commit(FETCH_TOKENS_SUCCESS, response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [UPDATE_ACTIVE_SORT_FIELD_TOKENS](context, params) {
      context.commit(UPDATE_ACTIVE_SORT_FIELD_TOKENS_M, params);
    },
    [UPDATE_SEARCH_TOKENS](context, params) {
      context.commit(UPDATE_SEARCH_TOKENS_M, params);
    },
    [CLEAR_SEARCH_TOKENS](context) {
      context.commit(CLEAR_SEARCH_TOKENS_M);
    },
    [DELETE_TOKEN](context, payload) {
      return new Promise((resolve, reject) => {
        context.dispatch(IS_LOADING).then(() => {
          EditorTokenService.delete(payload)
            .then((response) => {
              context.dispatch(FINISHED_LOADING).then(() => {
                resolve(response.data);
              });
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING).then(() => {
                reject(error);
              });
            });
        });
      });
    },
  },
};
