/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import MostPopularSitesService from '@/services/mostPopularSites.service';
import { FINISHED_LOADING, IS_LOADING } from '@/store/modules/layout/layoutActions.type';
import {
  CLEAR_SEARCH_MOST_POPULAR_SITES,
  FETCH_MOST_POPULAR_SITES, UPDATE_ACTIVE_SORT_FIELD_MOST_POPULAR_SITES, UPDATE_SEARCH_MOST_POPULAR_SITES,
  REMOVE_MOST_POPULAR_SITE, ADD_NEW_MOST_POPULAR_SITE, FETCH_MOST_POPULAR_SITES_WITH_IMAGE,
} from './mostPopularSitesActions.type';
import {
  CLEAR_SEARCH_MOST_POPULAR_SITES_M, FETCH_MOST_POPULAR_SITES_SUCCESS,
  UPDATE_ACTIVE_SORT_FIELD_MOST_POPULAR_SITES_M, UPDATE_SEARCH_MOST_POPULAR_SITES_M,
} from './mostPopularSitesMutations.type';

export default {
  state: {
    data: [],
    perPage: 25,
    currentPage: 1,
    lastPage: 1,
    from: 1,
    to: 10,
    total: 10,
    sort: [
      {
        name: 'Position ASC',
        key: 'position',
        dir: 'ASC',
        code: 'position_ASC',
        active: true,
      },
      {
        name: 'Position DESC',
        key: 'position',
        dir: 'DESC',
        code: 'position_DESC',
        active: true,
      },
      {
        name: 'ID ASC',
        key: 'id',
        dir: 'ASC',
        code: 'id_ASC',
        active: true,
      },
      {
        name: 'ID DESC',
        key: 'id',
        dir: 'DESC',
        code: 'id_DESC',
        active: false,
      },
      {
        name: 'Newest',
        key: 'created_at',
        dir: 'DESC',
        code: 'created_at_DESC',
        active: false,
      },
      {
        name: 'Oldest',
        key: 'created_at',
        dir: 'ASC',
        code: 'created_at_ASC',
        active: false,
      },
      {
        name: 'A-Z by address',
        key: 'address',
        dir: 'ASC',
        code: 'address_ASC',
        active: false,
      },
      {
        name: 'Z-A by address',
        key: 'address',
        dir: 'DESC',
        code: 'address_DESC',
        active: false,
      },
    ],
    search: '',
  },
  getters: {
    mostPopularSitesItems(state) {
      return state.data;
    },
    mostPopularSitesPerPage(state) {
      return state.perPage;
    },
    mostPopularSitesCurrentPage(state) {
      return state.currentPage;
    },
    mostPopularSitesLastPage(state) {
      return state.lastPage;
    },
    mostPopularSitesFrom(state) {
      return state.from;
    },
    mostPopularSitesTo(state) {
      return state.to;
    },
    mostPopularSitesTotal(state) {
      return state.total;
    },
    mostPopularSitesSortFields(state) {
      return state.sort;
    },
    mostPopularSitesActiveSort(state) {
      const activeSorts = state.sort.filter(sort => sort.active === true);
      return activeSorts[0];
    },
    mostPopularSitesSearch(state) {
      return state.search;
    },
  },
  mutations: {
    [FETCH_MOST_POPULAR_SITES_SUCCESS](state, data) {
      state.data = data.data;
      state.perPage = parseInt(data.perPage, 10);
      state.currentPage = data.currentPage;
      state.lastPage = data.lastPage;
      state.from = data.from;
      state.to = data.to;
      state.total = data.total;
    },
    [UPDATE_ACTIVE_SORT_FIELD_MOST_POPULAR_SITES_M](state, data) {
      const newSortFields = [];
      state.sort.forEach((element) => {
        element.active = false;
        if (element.code === data) {
          element.active = true;
        }
        newSortFields.push(element);
      });

      Vue.set(state, 'sort', newSortFields);
    },
    [UPDATE_SEARCH_MOST_POPULAR_SITES_M](state, data) {
      Vue.set(state, 'search', data);
    },
    [CLEAR_SEARCH_MOST_POPULAR_SITES_M](state) {
      Vue.set(state, 'search', '');
    },
  },
  actions: {
    [ADD_NEW_MOST_POPULAR_SITE](context, payload) {
      return new Promise((resolve, reject) => {
        context.dispatch(IS_LOADING).then(() => {
          MostPopularSitesService.create(payload)
            .then((response) => {
              // context.dispatch(GET_AUTH_USER_TABS).then();
              context.dispatch(FINISHED_LOADING);
              resolve(response.data);
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING);
              reject(error);
            });
        });
      });
    },
    [FETCH_MOST_POPULAR_SITES](context, params) {
      return new Promise((resolve, reject) => {
        // check search
        if (context.state.search && context.state.search !== '') {
          params.search = context.state.search;
        }
        // check active sort
        const activeSortArr = context.state.sort.filter(sort => sort.active === true);
        if (activeSortArr.length > 0) {
          params.sortBy = activeSortArr[0].key;
          params.sortDir = activeSortArr[0].dir;
        }

        // do the actual API call
        MostPopularSitesService.query(params)
          .then((response) => {
            context.commit(FETCH_MOST_POPULAR_SITES_SUCCESS, response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    [FETCH_MOST_POPULAR_SITES_WITH_IMAGE](context) {
      return new Promise((resolve, reject) => {
        context.dispatch(IS_LOADING).then(() => {
          // do the actual API call
          MostPopularSitesService.getMostPopularSitesWithImage()
            .then((response) => {
              context.dispatch(FINISHED_LOADING);
              resolve(response.data);
            })
            .catch((error) => {
              // console.log(error);
              context.dispatch(FINISHED_LOADING);
              reject(error);
            });
        });
      });
    },
    [REMOVE_MOST_POPULAR_SITE](context, payload) {
      return new Promise((resolve, reject) => {
        context.dispatch(IS_LOADING).then(() => {
          MostPopularSitesService.destroy(payload.id)
            .then((response) => {
              setTimeout(() => {
                context.dispatch(FINISHED_LOADING);
                resolve(response.data);
              }, 500);
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING);
              reject(error);
            });
        });
      });
    },
    [UPDATE_ACTIVE_SORT_FIELD_MOST_POPULAR_SITES](context, params) {
      context.commit(UPDATE_ACTIVE_SORT_FIELD_MOST_POPULAR_SITES_M, params);
    },
    [UPDATE_SEARCH_MOST_POPULAR_SITES](context, params) {
      context.commit(UPDATE_SEARCH_MOST_POPULAR_SITES_M, params);
    },
    [CLEAR_SEARCH_MOST_POPULAR_SITES](context) {
      context.commit(CLEAR_SEARCH_MOST_POPULAR_SITES_M);
    },
  },
};
