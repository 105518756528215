<template>
  <div id="rec-header-content" class="md-toolbar-row rec-header-content-mobile">
    <div class="md-toolbar-section-start" v-if="!isMobile">

      <div class="md-layout-item md-size-20" id="logo-header-container">
        <img id="logo" :src="`${publicPath}AMF_header_logo.png`" alt="AMF header logo" @click="home" data-v-step="0">
      </div>

      <search-bar class="md-layout-item md-size-50"></search-bar>

      <md-menu title="Discover more pages" class="md-layout-item md-size-15" md-size="auto" md-align-trigger>
        <md-button md-menu-trigger class="md-flat nav-menu-button" @click="expand('discover')" @focusout="handleFocusOut">
          {{ $t('navbar.discover') }}
          <md-icon v-if="DISCOVER">expand_less</md-icon>
          <md-icon v-else>keyboard_arrow_down</md-icon>
        </md-button>

        <md-menu-content style="z-index: 999999999;" class="discover" ref="discover">

          <template v-for="item in categoryMenuItem">
            <md-menu-item v-if="!item.is_username" v-bind:key="item.subdomain" :to="{
              name: item.type,
              params: { categoryName: item.subdomain }
            }">{{
  item.subdomain.match(/blogs([^s]|^)/) || item.subdomain.match(/games([^s]|^)/) ||
  item.subdomain.match(/week([^s]|^)/) ?
  item.subdomain.replace('week', '')
    .replace('blogs', '')
    .replace('games', '')
  : item.subdomain
}}
            </md-menu-item>
            <md-menu-item v-else v-bind:key="item.subdomain" :to="{
              name: 'myFaves',
              params: { username: item.subdomain }
            }">{{ item.subdomain }}
            </md-menu-item>
          </template>

          <md-menu-item :to="{ name: 'weeklyFaves', params: { year: new Date().getFullYear() } }">
            {{ $t('navbar.weeklyFaves') }}
          </md-menu-item>

          <md-menu-item :to="{ name: 'weeklyGames', params: { year: 2020 } }">
            {{ $t('navbar.weeklyGames') }}
          </md-menu-item>
          <md-menu-item :to="{ name: 'favesByCountry' }"> {{ $t('navbar.favesByCountry') }}</md-menu-item>
          <md-menu-item href="https://blog.allmyfaves.com" target="_blank">
            {{ $t('navbar.blog') }}
          </md-menu-item>

          <md-menu-item v-for="item in topFavesItems" v-bind:key="item.subdomain" :to="{
            name: item.type,
            params: { year: item.subdomain }
          }">{{
  item.subdomain.match(/blogs([^s]|^)/) || item.subdomain.match(/games([^s]|^)/) ||
  item.subdomain.match(/week([^s]|^)/) ?
  item.subdomain.replace('week', '')
    .replace('blogs', '')
    .replace('games', '')
  : item.subdomain
}}
          </md-menu-item>

        </md-menu-content>
      </md-menu>

      <div title="Click to see more tools" class="md-toolbar-section-end md-layout-item md-size-10" v-if="!isMobile">
        <md-button md-menu-trigger class="md-icon-button" v-if="!isAuthenticated" :to="{ name: 'userLogin' }">
          <md-avatar>
            <img :src="`${publicPath}profile-image.png`">
          </md-avatar>
        </md-button>
        <md-menu md-size="big" md-align-trigger v-if="isAuthenticated" :md-offset-y="y" :md-offset-x="x">
          <div class="user-acc">
            <p class="user-username">{{ user.username }}</p>
            <md-button md-menu-trigger class="md-flat md-icon-button avatar-button">
              <md-avatar data-v-step="1">
                <img :src="user.userProfileImagePath" alt="Avatar" v-if="user.userProfileImagePath">
                <img :src="`${publicPath}profile-image.png`" v-else>
              </md-avatar>
            </md-button>
          </div>
          <md-menu-content class="profile-menu">
            <md-menu-item class="nav-menu-button-sidebar" @click="myFaves"> {{
              $t('navbar.myFaves')
            }}
            </md-menu-item>
            <md-menu-item class="nav-menu-button-sidebar" :to="{ name: 'faveItButton' }"> {{
              $t('navbar.faveButton')
            }}
            </md-menu-item>
            <md-menu-item class="nav-menu-button-sidebar" href="https://blog.allmyfaves.com" target="_blank">
              {{ $t('navbar.blog') }}
            </md-menu-item>
            <md-menu-item class="nav-menu-button-sidebar" :to="{ name: 'faq' }"> {{ $t('navbar.faq') }}</md-menu-item>
            <md-menu-item class="nav-menu-button-sidebar" :to="{ name: 'account' }"> {{
              $t('navbar.settings')
            }}
            </md-menu-item>
            <md-menu-item class="nav-menu-button-sidebar" :to="{ name: 'adminDashboard' }" v-if="user.role == 'admin'">
              Admin Dashboard
            </md-menu-item>
            <md-menu-item class="nav-menu-button-sidebar help-btn" @click="enableTour"> Help</md-menu-item>
            <md-menu-item class="nav-menu-button-sidebar" v-if="isAuthenticated" @click="logout"> {{
              $t('logout')
            }}
            </md-menu-item>
          </md-menu-content>
        </md-menu>
      </div>
    </div>

    <div class="md-layout md-layout-mobile" v-if="isMobile">
      <div class="md-layout-item nav-item-mobile">
        <md-button id="mobile-sidebar" class="md-icon-button" @click="toggleSidebar" data-v-step="1">
          <i class="fas fa-bars fa-2x"></i>
        </md-button>
        <div id="logo-header-container-mobile">
          <img id="logo-mobile" :src="`${publicPath}AMF_small_logo.png`" alt="AMF header logo" @click="home"
            data-v-step="0">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import smallDevicesCheckMixin from '@/mixins/smallDevicesCheckMixin';
import sidebarMixin from '@/mixins/sidebarMixin';
import loggedInUserMixin from '@/mixins/loggedInUserMixin';
import SearchBar from '@/components/common/SearchBar.vue';

export default {
  name: 'RecHeader',
  components: {
    SearchBar,
  },
  mixins: [
    smallDevicesCheckMixin,
    sidebarMixin,
    loggedInUserMixin,
  ],
  data: () => ({
    publicPath: process.env.BASE_URL,
    windowWidth: null,
    x: -90,
    y: 10,
    mdOffsetX: -30,
    DISCOVER: false,
    TYPE_TOP_FAVES: false,
  }),
  mounted() {
    this.checkDevice();
    this.smallDeviceCheck();
  },
  methods: {
    enableTour() {
      this.$tours.myTour.start();
    },
    handleFocusOut() {
      this.DISCOVER = false;
      this.TYPE_TOP_FAVES = false;
    },
    myFaves() {
      if (this.currentRouteName === 'home') {
        this.$router.push({ name: 'myFaves', params: { username: this.user.username } });
      } else {
        this.$router.push({ name: 'myFaves', params: { username: this.user.username } });
        this.$router.go(this.$router.currentRoute);
      }
    },
    expand(item) {
      if (item === 'TYPE_TOP_FAVES') {
        if (!this.$refs.TYPE_TOP_FAVES.shouldRender) {
          this.TYPE_TOP_FAVES = true;
          this.DISCOVER = false;
        } else {
          this.TYPE_TOP_FAVES = false;
          this.DISCOVER = false;
        }
      } else if (item === 'discover') {
        if (!this.$refs.discover.shouldRender) {
          this.TYPE_TOP_FAVES = false;
          this.DISCOVER = true;
        } else {
          this.TYPE_TOP_FAVES = false;
          this.DISCOVER = false;
        }
      }
    },
    home() {
      if (this.$route.path !== '/') {
        this.$router.push('/');
      }
    },
  },
  computed: {
    isVisible() {
      return this.currentRouteName !== 'userLogin'
        && this.currentRouteName !== 'userRegister' && this.currentRouteName !== 'userForgotPassword';
    },
    currentRouteName() {
      return this.$route.name;
    },
    topFavesItems() {
      const clone = Object.assign({}, this.$store.getters.navigationStructure);
      delete clone.TYPE_CATEGORY;
      return clone.TYPE_TOP_FAVES;
    },
    categoryMenuItem() {
      if (this.$store.getters.navigationStructure != null && this.$store.getters.navigationStructure.TYPE_CATEGORY != null) {
        return this.$store.getters.navigationStructure.TYPE_CATEGORY;
      }
      return [];
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
};
</script>

<style lang="scss">
.profile-menu {
  background: #FFFFFF !important;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.22) !important;
  border-radius: 12px !important;
  max-height: unset !important;
  top: 10px;
  z-index: 999999999999;

  .md-menu-content-container {
    border-radius: 12px !important;
  }

  .help-btn {
    .md-list-item-button {
      color: #1020A9 !important;
    }
  }

  .md-list-item-button {
    &:hover {
      border-radius: 6px;
      color: #F93EDE !important;
    }

    color: #f93ede !important;
  }
}

.multiselect__element:hover {
  background-color: #F93EDE;
  border-radius: 6px;
}

.user-acc {
  display: flex;
  justify-content: center;
  align-items: center;

}

.user-username {
  color: #000;
  font-weight: 500;
  text-transform: capitalize;
  word-break: break-all;
  max-height: 115px;
  overflow-y: auto
}

@import '@/assets/scss/layout/rec-header.scss';
</style>
