/* eslint-disable import/no-cycle */
import Vue from 'vue';

const MODULE_ROUTE = 'unlistedSites';

export default {
  query(params) {
    return Vue.axios.get(MODULE_ROUTE, { params }).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  get(id) {
    return Vue.axios.get(`${MODULE_ROUTE}/${id}`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  create(data) {
    return Vue.axios.post(MODULE_ROUTE, data).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  update(id, data) {
    return Vue.axios.post(`${MODULE_ROUTE}/update/${id}`, data).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  destroy(id) {
    return Vue.axios.delete(`${MODULE_ROUTE}/${id}`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  reject(id) {
    return Vue.axios.post(`${MODULE_ROUTE}/un-fetch/${id}`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  unFetchByToken(tokenId) {
    return Vue.axios.post(`${MODULE_ROUTE}/un-fetch-by-token/${tokenId}`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  markOk(id) {
    return Vue.axios.post(`${MODULE_ROUTE}/mark-ok/${id}`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
};
