/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import EditorTokenService from '@/services/editorTokens.service';
import { FINISHED_LOADING, IS_LOADING } from '@/store/modules/layout/layoutActions.type';
import {
  FETCH_TOKEN, UPDATE_TOKEN_A, CREATE_TOKEN,
} from './editorTokenActions.type';
import { FETCH_TOKEN_SUCCESS } from './editorTokenMutations.type';

export default {
  state: {
    editorTokenData: {},
  },
  getters: {
    editorTokenData(state) {
      return state.editorTokenData;
    },
  },
  mutations: {
    [FETCH_TOKEN_SUCCESS](state, data) {
      Vue.set(state, 'editorTokenData', data);
    },
  },
  actions: {
    [CREATE_TOKEN](context, params) {
      return new Promise((resolve, reject) => {
        context.dispatch(IS_LOADING).then(() => {
          EditorTokenService.create(params)
            .then((response) => {
              context.dispatch(FINISHED_LOADING).then();
              resolve(response.data);
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING).then();
              reject(error);
            });
        });
      });
    },
    [FETCH_TOKEN](context, id) {
      return new Promise((resolve, reject) => {
        EditorTokenService.get(id)
          .then((response) => {
            context.commit(FETCH_TOKEN_SUCCESS, response.data.data.token);
            resolve(response.data.data.token);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [UPDATE_TOKEN_A](context, payload) {
      return new Promise((resolve, reject) => {
        EditorTokenService.update(payload.id, payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
