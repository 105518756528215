<template>
  <div class="page-container">
    <nprogress-container></nprogress-container>
    <md-app id="app" md-mode="fixed">
        <md-app-toolbar id="rec-header" md-elevation="1" v-if="currentRouteName !=='bookmarklet'">
        <rec-header></rec-header>
        </md-app-toolbar>
      <md-app-drawer :md-active.sync="isSidebarVisible"
                     :class="[isMobile ? 'md-left mobile-siderbar' : 'md-right']"
                     v-if="isMobile && currentRouteName !== 'userLogin' && currentRouteName !=='bookmarklet'">
        <rec-sidebar-mobile></rec-sidebar-mobile>
      </md-app-drawer>

      <md-app-content id="content-app">
            <rec-banner :banner="banner" v-if="banner"></rec-banner>
            <router-view></router-view>
            <rec-ads :HasNotAdsSection="HasNotAdsSection" :hasSubscription="hasSubscription" adsType="bottom" class="more-lower-space">
            </rec-ads>
            <rec-footer :HasNotAdsSection="HasNotAdsSection"
              :hasSubscription="hasSubscription" v-if="currentRouteName !== 'userLogin' &&
               currentRouteName !=='userRegister' && currentRouteName !=='bookmarklet' &&
               currentRouteName !== 'step1Login'">
            </rec-footer>
            <CellModal
              :isCellModalVisible="selectedTabCellIdToEdit"
              @close="closeModal"
            />
            <ShareTabModal
                :isShareTabModalVisible="isShareTabModalVisible"
                :sharedTabObject="sharedTabObject"
                v-show="isShareTabModalVisible"
                @close="closeSharedModal">
           </ShareTabModal>
          <v-tour name="myTour" :steps="steps" id="tour"></v-tour>
      </md-app-content>
    </md-app>

    <rec-cookie v-if="isCookieVisible" />
  </div>
</template>

<script>
import NprogressContainer from 'vue-nprogress/src/NprogressContainer.vue';
import RecAds from '@/components/layout/RecAds.vue';
import RecHeader from '@/components/layout/RecHeader.vue';
import RecBanner from '@/components/layout/RecBanner.vue';
import RecSidebarMobile from '@/components/layout/RecSidebarMobile.vue';
import RecFooter from '@/components/layout/RecFooter.vue';
import RecCookie from '@/components/layout/RecCookie.vue';
import CellModal from '@/components/modals/CellModal.vue';
import ShareTabModal from '@/components/modals/ShareTabModal.vue';
import { RESET_TAB_CELL_EDITABLE_ID_ADMIN } from '@/store/actions.type';
import sidebarMixin from '@/mixins/sidebarMixin';
import adsHelperFunctionsMixin from '@/mixins/adsHelperFunctionsMixin';
import smallDevicesCheckMixin from '@/mixins/smallDevicesCheckMixin';
import {
  GET_AUTH_USER_TABS, RESET_TAB_CELL_EDITABLE_ID, SET_SHARE_TAB_MODAL_VALUE, SET_TAB_MODAL_VALUE, SET_EDITABLE_TAB_MODAL_VALUE,
} from '@/store/modules/auth/authUserTabs/authUserTabsActions.type';
import { FETCH_NAVIGATION_STRUCTURE } from '@/store/modules/navigationStructure/navigationStructureActions.type';
import { SET_TOUR } from '@/store/modules/layout/layoutActions.type';
import { FETCH_WEEKLY_SETTING_A } from '@/store/modules/admin/single/setting/settingActions.type';
import { UPDATE_UI_CHANNEL } from './updateUiChannel';
import { SUB_DOMAIN_CHANGE_EVENT, USER_SPRITE_UPDATED_EVENT } from './pusher';

export default {
  name: 'RECApp',
  components: {
    RecAds,
    RecHeader,
    RecFooter,
    NprogressContainer,
    CellModal,
    RecSidebarMobile,
    RecBanner,
    RecCookie,
    ShareTabModal,
  },
  mixins: [
    sidebarMixin,
    smallDevicesCheckMixin,
    adsHelperFunctionsMixin,
  ],
  data: () => ({
    windowWidth: null,
    centerVideoAd: null,
    leftVideoAd: null,
    isNotOverlapped: false,
    isCellModalVisible: false,
    steps: [
      {
        target: '[data-v-step="0"]',
        content: '<h2 style="line-height: initial; font-size: 17px;"> AllMyFaves serves as your starting point for the Web. </h2>'
        + '<p style="font-size: 15px;"> An easy way to access all your Web favorites.</p>',
        params: {
          placement: 'bottom',
        },
      },
      {
        target: '[data-v-step="1"]',
        content: 'This is the user menu.',
        params: {
          placement: 'bottom',
        },
      },
      {
        target: '[data-v-step="2"]',
        content: 'Your tabs where you can save sites.',
        params: {
          placement: 'bottom',
        },
      },
      {
        target: '[data-v-step="3"]',
        content: 'Click the plus icon to create a new tab.',
        params: {
          placement: 'bottom',
        },
      },
      // {
      //   target: '[data-v-step="4"]',
      //   content: 'Click on more button to expand the tab list',
      //   params: {
      //     placement: 'bottom',
      //   },
      // },
      {
        target: '[data-v-step="5"]',
        content: 'Click on the pencil icon to edit each tab.',
        params: {
          placement: 'bottom',
        },
      },
      {
        target: '[data-v-step="6"]',
        content: 'There are your saved sites. <br>  Click on it to access the saved site.',
        params: {
          placement: 'bottom',
        },
      },
      {
        target: '[data-v-step="7"]',
        content: 'Click on pencil icon to edit the cell sites.',
        params: {
          placement: 'bottom',
        },
      },
      {
        target: '[data-v-step="8"]',
        content: 'Click on plus icon to add a new site.',
        params: {
          placement: 'bottom',
        },
      },
    ],
  }),
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    selectedTabCellIdToEdit: {
      get() {
        if (this.$store.getters.selectedTabCellIdToEdit === undefined) {
          return true;
        }
        if (this.$store.getters.selectedTabCellIdToEdit !== null && this.$store.getters.selectedTabCellIdToEdit !== -1) {
          return true;
        }
        if (this.$store.getters.selectedTabCellIdToEditAdmin !== null) {
          return true;
        }
        return false;
      },
    },
    tour() {
      return this.$store.getters.tour;
    },
    isSidebarVisible() {
      return this.$store.getters.isSidebarVisible;
    },
    banner() {
      const BANNER_PAGES = this.$store.getters.bannerPages;
      return (this.$store.getters.banner !== 'null' && BANNER_PAGES.indexOf(this.currentRouteName) !== -1) ? this.$store.getters.banner : null;
    },
    isCookieVisible() {
      return !this.$store.getters.acceptCookies;
    },
    isShareTabModalVisible() {
      return this.$store.getters.isShareTabModalVisible;
    },
    sharedTabObject() {
      return this.$store.getters.selectedTab;
    },
  },
  updated() {
    this.$nextTick(function vv() {
      if (this.tour) {
        this.$tours.myTour.start();
        this.$store.dispatch(SET_TOUR, false);
      }
    });
    this.handleConnatix();
  },
  mounted() {
    this.checkDevice();
    this.smallDeviceCheck();
    this.loadNavigationStructure();
    if (this.tour) {
      this.$tours.myTour.start();
      this.$store.dispatch(SET_TOUR, false);
    }
    this.fetchWeeklyLine();
    this.loadPusher();
    setTimeout(() => {
      this.handleConnatix();
    }, 1000);
  },
  methods: {
    handleConnatix() {
      const connatixDiv = document.getElementsByClassName('cnx-center')[0];
      if (connatixDiv !== undefined) {
        if (this.HasNotAdsSection || this.hasSubscription) {
          connatixDiv.style.display = 'none';
        } else {
          connatixDiv.style.display = '';
        }
      }
      document.getElementsByTagName('main')[0].style.transform = 'none';
    },
    loadPusher() {
      UPDATE_UI_CHANNEL.bind(SUB_DOMAIN_CHANGE_EVENT, () => {
        this.loadNavigationStructure();
      });
      UPDATE_UI_CHANNEL.bind(USER_SPRITE_UPDATED_EVENT, (data) => {
        if (Number(data?.user_id) === this.user?.id) { // data.all to handle case update logo for a site
          this.$store.dispatch(GET_AUTH_USER_TABS).then();
        }
      });
    },
    fetchWeeklyLine() {
      this.$store.dispatch(FETCH_WEEKLY_SETTING_A);
    },
    closeModal() {
      this.$store.dispatch(RESET_TAB_CELL_EDITABLE_ID);
      this.$store.dispatch(RESET_TAB_CELL_EDITABLE_ID_ADMIN);
      // cuz state isnot reset to false if you close it by clicking outside
      this.$store.dispatch(SET_TAB_MODAL_VALUE, false);
      this.$store.dispatch(SET_EDITABLE_TAB_MODAL_VALUE, false);
      this.$store.dispatch(SET_SHARE_TAB_MODAL_VALUE, false);
    },
    closeSharedModal() {
      this.$store.dispatch(SET_SHARE_TAB_MODAL_VALUE, false);
    },
    loadNavigationStructure() {
      this.$store.dispatch(FETCH_NAVIGATION_STRUCTURE)
        .then((response) => {
          this.$log.info('FETCH_NAVIGATION_STRUCTURE', response);
        })
        .catch((error) => {
          this.$log.error('FETCH_NAVIGATION_STRUCTURE', error);
        });
    },
    getOffset(el) {
      const rect = el.getBoundingClientRect();
      return {
        bottom: rect.bottom,
        top: rect.top,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  height: 100vh;
}

@media (max-width: 760px) {
  #content-app {
    position: unset;
  }
}

#left-video-ad.hide {
  opacity: 0;
}
.md-app {
  min-height: 100%;
  main {
    transform: none !important;
  }

  .md-drawer + main {
    display: none;
  }

  .md-content {
    padding: 0;
    border: none;
  }
}

.md-drawer {
  width: 200px;
  max-width: calc(100vw - 125px);
}

.container-search {
  width: 70%;
  margin: 50px auto;
}

.mobile-siderbar {
  width: 281px;
}

.more-lower-space {
  height: 150px;
  padding-bottom: 2rem;
  padding-top: 1.3rem;
}

::v-deep #tour .v-step {
  max-width: 475px;
}

::v-deep #tour .v-step .v-step__content :first-child {
  font-size: 17px;
}

::v-deep #tour .v-step .v-step__button {
  padding-left: 15px;
  padding-right: 15px;
  height: 45px;
  width: 130px;
  font-weight: 600;
}

</style>
