/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import FavLinesService from '@/services/favLines.service';
import {
  FETCH_FAV_LINE, FETCH_FAV_LINE_FAV_CELLS,
} from '../../../../actions.type';
import {
  FETCH_FAV_LINE_SUCCESS, FETCH_FAV_LINE_FAV_CELLS_SUCCESS,
} from '../../../../mutations.type';

export default {
  state: {
    favLineData: {},
    favLineFavCellsData: {},
  },
  getters: {
    favLineData(state) {
      return state.favLineData;
    },
    favLineFavCellsData(state) {
      return state.favLineFavCellsData;
    },
  },
  mutations: {
    [FETCH_FAV_LINE_SUCCESS](state, data) {
      Vue.set(state, 'favLineData', data.favLine);
    },
    [FETCH_FAV_LINE_FAV_CELLS_SUCCESS](state, data) {
      Vue.set(state, 'favLineFavCellsData', data.favCells);
    },
  },
  actions: {
    [FETCH_FAV_LINE](context, id) {
      return new Promise((resolve, reject) => {
        FavLinesService.get(id)
          .then((response) => {
            context.commit(FETCH_FAV_LINE_SUCCESS, response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [FETCH_FAV_LINE_FAV_CELLS](context, id) {
      return new Promise((resolve, reject) => {
        FavLinesService.getFavCellsForFavLine(id)
          .then((response) => {
            context.commit(FETCH_FAV_LINE_FAV_CELLS_SUCCESS, response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
