import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: {
      sidebar: {
        settings: 'Settings',
        account: 'Account',
        billing: 'Billing',
        export: 'Export Faves',
      },
      userDashboard: 'User Dashboard',
      adminDashboard: 'Admin Dashboard',
      mail: 'info@allmyfaves.com',
      blogPost: 'Post',
      admins: 'Admins',
      usernameMinimumLength: 'The username should be longer than 3 characters',
      validUsername: 'Please provide a valid username address.',
      missingValue: 'Required field',
      logout: 'Log out',
      loginBtn: 'Log in',
      mostPopular: 'Most Popular',
      weekley: 'Weekly Faves',
      userCard: {
        privateProfile: 'Private profile',
        publicProfile: 'Public profile',
        favorite: 'favorite',
        bookmark: 'bookmark',
        share: 'share',
      },
      account: {
        upload: 'Upload a new Photo...',
        username: "Username",
        mail: 'E-mail',
        privateAccount: 'Keep my account private',
        notifications: 'Receive notifications',
        updates: 'Receive updates about All My Faves',
        deleteAccount: 'Delete Account',
        text1: 'Visualize',
        text2: 'your',
        text3: 'Bookmarks',
      },
      bookmarklet: {
        title: 'Add a bookmark',
        titleRequired: 'Title Required',
        addNewTab: 'Add New Tab',
        addBookmark: 'Add bookmark',
        drag: 'Drag this Button to your Bookmarks Bar',
        install: 'To install the "Fave It" button:',
        installBookmarkBtn: 'To install the "Add it to your bookmarks" button:',
        installex1: 'Display your Bookmarks by clicking the Ctrl + Shift + B on your keyboard',
        installex2: 'Drag the "Fave It" button to your Bookmarks bar',
        installex3: 'When you are browsing the web, press the "Fave It" button to bookmark a website in your account',
        installex4: 'Drag the "Add it to your bookmarks" button to your Bookmarks bar',
        installex5: 'When you are browsing the web, press the "Add it to your bookmarks" button to bookmark a website in your account',
      },
      login: {
        title: 'Welcome back to',
        email: 'Email',
        username: 'Username',
        password: 'Password',
        requiredField: 'Required field.',
        minLengthUsername: 'The username should be minimum 3 characters.',
        minLengthPassword: 'The password should be minimum 5 characters.',
        forgotUsername: 'Forgot username?',
        forgotPassword: 'Forgot password?',
        loginBtn: 'Login',
        noAccount: "Don't have an account?",
        getStarted: 'Get started',
        goToLogin: 'Go to Login Page',
        confirmedAccount: 'Account has been confirmed',
      },
      faves: {
        favesByCountryTitle: 'Faves By Country',
        hide: 'Hide',
        show: 'Show',
        topFavesTitle: 'Top Faves',
      },
      userTopTabs: {
        addBtn: 'Add your own faves',
        cellTitle: 'Site Details',
        validUrl: 'Url is not valid!',
        delete: 'delete',
        logo: 'logo',
        save: 'save',
        mostPopularSiteTitle: 'Add new Site to Most Popular List',
        sitetitle: 'Site',
        position: 'Position',
        close: 'Close',
        tabDetails: 'Tab details',
        private: 'private',
        public: 'public',
        share: 'share',
        copy: 'copy link',
        settings: 'Setting',
        settingModalTitle: 'Add New Setting',
        key: 'Key',
        value: 'Value',
        description: 'Description',
        upgradePlan: 'Upgrade your plan to add more tabs',
        changePlan: 'See our plans'
      },
      forgotPassword: {
        title: 'Forgot Password',
        forgotBtn: 'FORGOT PASSWORD',
      },
      resetPassword: {
        title:'Reset Password',
        resetBtn: 'RESET PASSWORD',
        passwordMinLength: 'The password should be longer than 8 characters',
      },
      register: {
        title: 'Create your AllMyFaves Account',
        email: 'Email',
        username: 'Username',
        password: 'Password',
        requiredField: 'Required field',
        registerBtn: 'Create account',
        haveAccount: 'Have an account?',
        signInBtn: 'Sign in',
        acceptTC: 'I accept',
        pleaseAcceptTC: 'Please accept Terms&Conditions',
        termsConditions: 'T&C',
        visualBookmarks: 'Effortless Organization',
        visualBookmarksTxt1: 'Simplify bookmarking with AllMyFaves visual tool.',
        visualBookmarksTxt2: 'Our visual bookmarking tool allows you to add and manage all your favorite sites in one place.',
        discoverMore: 'Discover Amazing Websites',
        discoverMoreTxt: 'Explore a curated selection of awesome websites.',
        discoverMoreTxt1: 'AllMyFaves offers a curated selection of awesome websites across a wide range of topics,',
        discoverMoreTxt2: 'so you can easily find something new to love.',
        faveIt: 'Save Your Favorites with Ease',
        faveItTxt: 'Save your links quickly with the "Fave It" button.',
        faveItTxt1: 'use AllMyFaves "Fave It" button to quickly and easily save your favorite pages.',
        faveItTxt2: 'Access them anytime, anywhere.',
        everywhere: 'Your Bookmarks, Anywhere',
        everywhereTxt: 'Access your bookmarks on any device with AllMyFaves.',
        everywhereTxt1: 'Our tool is available on any computer, smartphone, or tablet,',
        everywhereTxt2: 'so you can access your favorite sites no matter where you are.',
        invalidEmail: 'Email is not valid',
        minLengthUsername: 'The username should be minimum 3 characters.',
        minLengthPassword: 'The password should be minimum 5 characters.',
      },
      aboutUs: {
        aboutUsPageTitle: 'About Us',
        homepageTitle: 'All My Faves is the Ultimate Homepage',
        homepageContent: 'We like to keep it simple. Why search? is the essence of AllMyFaves\' philosophy. We believe the Internet should be an inspiring, easy and free experience for everyone. We, too, think that there must be an appealing and simple alternative to traditional Internet navigation. That is why we have taken up the task of simplification so that end-users could find what they\'re looking for quickly, whether they are interested in Entertainment, Games, Kids, Shopping, Travel or anything else. With AllMyFaves, users can smoothly cruise on the vast Internet highway without wasting valuable time or having to sieve through irrelevant search results.',
        homepageContent2: 'Our visual platform not only directs users to their sought-after information fast but also introduces them to new and exciting sites, both of the same and different categories. We achieve this through a strict selection process and a back-and-forth dialogue among the AllMyFaves team members. This way, AllMyFaves acts as a pioneering force of Internet browsing, searching and learning, thereby offering nothing but the absolute best of what the web has to offer. Today, when much of the searches we perform produce considerable numbers of spam, fraud and aggregation sites, we feel someone needs to step up and sift through the Internet so that Internet users\' experience is a positive, to the point and no-nonsense one. AllMyfaves has a calling and we\'re dead set on doing it right. What a better way to do so than by the use of the human brain.',
        historyTitle: 'History and Approach',
        historyContent: 'Back in 2006, Shachar Pessis grew frustrated by the cumbersome and convoluted nature of the Internet. To find something on the web, he had to type in URLs, use his ever-increasing list of bookmarks, enter key words in search engine boxes, or be acquainted with an Internet savvy friend who could recommend relevant sites to him. Sure enough, Shachar learned he was not alone: 72.3 percent of adult Americans experience \'search engine fatigue\' either always, usually or sometimes when searching for a topic on the web ("The State of Search" - compiled by Autobytel Inc. with Kelton Research, October 2007). Discovering new sites was even more challenging and time-consuming, and rarely did Shachar accomplish this in a fast and easy manner.',
        historyContent2: 'Shachar shared his discontentment with Roy Pessis, his brother. Together they came to the conclusion that a better alternative must exist, one that applies to and is shareable by everyone, everywhere. The two brothers then held brainstorming sessions and came up with the AllMyFaves concept. They envisioned a virtual directory that includes a list of top and most visited sites in major daily-used subject categories, a list they knew would introduce new and interesting things to others as it grew larger. Shachar and Roy aimed for a simple way by which users can discover new fields of interest without having to think about these beforehand or surfing the Internet with the initial intent of learning new things.',
        historyContent3: 'The AllMyFaves\' Internet team scans the Internet for the best and latest sites on the web. Our experts are constantly hard at work in examining the Internet\'s various aspects, categories and sites. By doing so, we eliminate all the crudeness of the Internet and offer users a purified, virtually distilled version of the web. Becoming a Faves expert is no easy task; new \'fave selectors\' who come aboard AllMyFaves go through a personal and ongoing training program in order to perform high quality filtration of the thousands of sites they come across every week. Indeed, we are stern believers in human editing skills versus those of a machine or software.',
        myFavesTitle: 'My Faves',
        myFavesContent: 'The My Faves feature is one of AllMyfaves\' most exciting components (',
        myFavesContentLink: 'click here to join',
        myFavesContent2: '). Although we do our best in making AllMyFaves your one-stop-shop for all your Internet needs, we do recognize the fact that each user is into different fields of interests and activities. Therefore, we\'re giving users their own creation powers for free. Once you\'ve signed up and have logged in, you\'ll have the option of adding your choice of new sites.',
        weeklyFaves: 'Weekly Faves',
        weeklyFavesContent: 'Updated every Monday, the Weekly Faves are our hand-picked sites for that week. These are the sites we were most impressed by and the ones we wish to pay forward to our users. Think of it as the \'crème de la crème\' of the Internet on a weekly basis. Accordingly, the Weekly Faves offer users a little bit of everything each week with the added value of introducing new sites and fields of interest to users.',
        missedSomethingTitle: 'We Missed Something?',
        missedSomethingContent: 'Check out our ',
        missedSomethingLink: 'Frequently Asked Questions',
        missedSomethingLink2: 'write us',
        missedSomethingContent2: ' for more info. Or ',
        missedSomethingContent3: ' with whatever is on your mind.',
        joinUsTitle: 'Join Us',
      },
      press: {
        pressPageTitle: 'Press Room'
      },
      bookmark: {
        faveBookmarkPageTitle: 'The Bookmark',
        saveBookmark: 'Easily Save Your Faves',
      },
      contact: {
        title: 'Contact Us',
        subtitle1: 'Your opinion means a great deal to us. If you have a comment, suggestion, complaint or recommendation of a site/category, Please mail us at',
        subtitle2: 'or fill out the form below.',
        checkboxTitle: 'How did you hear about us?',
        checkbox: {
          search: 'Search',
          referringSite: 'Reffering Site',
          tv: 'Television',
          radio: 'Radio',
          newspaper: 'Newspaper',
          world: 'World Of Mouth'
        },
      },
      footer: {
        titleSection1: 'All My Faves',
        aboutUs: 'About Us',
        join: 'Join',
        tellAFriend: 'Tell A Friend',
        press: 'Press',
        blog: 'Blog',
        adminArea: "Admin Area",
        support: 'Support',
        faq: 'FAQ',
        wantMore: 'Want More?',
        contactUs: 'Contact Us',
        oldWebsite: 'Old Website',
        downloadApp: "Download the App",
        privacy: 'Privacy Policy',
        terms: 'Terms of Use',
        rights: 'All rights reserved.',
      },
      navbar: {
        TYPE_TOP_FAVES: 'Top Faves',
        TYPE_GAMES: 'Top Games',
        TYPE_BLOGS: 'Top Blogs',
        TYPE_CATEGORY: 'Categories',
        shop: 'Shop',
        sports: 'Sports',
        shopping: 'Shopping',
        apparel: 'Apparel',
        homeElectronics: 'Home & Electronics',
        discover: 'Discover',
        weeklyFaves: 'Weekly Faves',
        weeklyGames: 'Weekly Games',
        favesByCountry: 'Faves By Country',
        blog: 'Blog',
        settings: 'Settings',
        myFaves: 'MyFaves',
        gifts: 'Gifts',
        entertainment: 'entertainment',
        apps: 'Apps',
        instagram: 'Instagram',
        giftguide: 'Gift Guide',
        faveButton: 'Fave It Button',
        faq: 'FAQ',
        sideBarHome: 'Navigation',
        home: 'Home',
        profile: 'Profile',
        adminMenu: 'Admin Menu',
        categories: 'Categories',
        blogs: 'Blogs',
        biz: 'Business',
        edu: 'Education',
        ent: 'Entertainment',
        kids: 'Kids',
        npo: 'NPO',
        travel: 'Travel',
        tvonline: 'Tv Online',
        xmas: 'Xmas',
      },
    },
    ru: {
      sidebar: {
        settings: 'настройки',
        account: 'учетная запись',
        billing: 'выставление счетов',
        export: 'экспортные фавориты',
      },
      userDashboard: 'панель пользователя',
      adminDashboard: 'панель администратора',
      mail: 'info@allmyfaves.com',
      blogPost: 'Post',
      admins: 'админы',
      missingValue: 'Обязательное поле',
      usernameMinimumLength: 'Имя пользователя должно быть длиннее 3 символов.',
      validUsername: 'Пожалуйста, укажите действующий адрес пользователя.',
      logout: 'Выйти',
      mostPopular: 'Самый популярный',
      weekley: 'Еженедельные лица',
      userCard: {
        privateProfile: 'Личный профиль',
        publicProfile: 'Общедоступный профиль',
        favorite: 'любимый',
        bookmark: 'закладка',
        share: 'Поделиться',
      },
      account: {
        upload: 'Загрузить новую фотографию...',
        username: "Имя пользователя",
        mail: 'Эл. почта',
        privateAccount: 'Держите мою учетную запись приватной',
        notifications: 'Получать уведомления',
        updates: 'Получать обновления обо всех моих любимых',
        deleteAccount: 'Удалить аккаунт',
        text1: 'Визуализировать',
        text2: 'ваш',
        text3: 'Закладки',
      },
      bookmarklet: {
        title: 'Добавить закладку',
        titleRequired: 'Требуется название',
        addNewTab: 'Добавить новую вкладку',
        addBookmark: 'Добавить закладку',
        drag: 'Перетащите эту кнопку на панель закладок',
        install: 'Чтобы установить кнопку «Добавить в избранное»:',
        installex1: 'Отобразите свои закладки, нажав Ctrl + Shift + B на клавиатуре',
        installex2: 'Перетащите кнопку «Добавить в избранное» на панель закладок.',
        installex3: 'При просмотре веб-страниц нажмите кнопку «Добавить в избранное», чтобы добавить веб-сайт в закладки в своей учетной записи.',
      },
      login: {
        title: 'Добро пожаловать обратно в',
        email: 'Эл. адрес',
        username: 'Username',
        password: 'имя пользователя',
        requiredField: 'Обязательное поле.',
        minLengthUsername: 'Имя пользователя должно состоять минимум из 3 символов..',
        minLengthPassword: 'Пароль должен состоять минимум из 5 символов..',
        forgotUsername: 'забыл имя пользователя?',
        forgotPassword: 'забыл пароль?',
        loginBtn: 'Авторизоваться',
        noAccount: "Нет учетной записи?",
        getStarted: 'Начать',
        goToLogin: 'Перейти на страницу входа',
        confirmedAccount: 'Аккаунт подтвержден',
      },
      faves: {
        favesByCountryTitle: 'Любимые страны',
        hide: 'Скрывать',
        show: 'Показать',
        topFavesTitle: 'Top Faves',
      },
      userTopTabs: {
        addBtn: 'Добавьте своих любимых',
        cellTitle: 'Детали сайта',
        validUrl: 'URL недействителен!',
        delete: 'удалять',
        logo: 'логотип',
        save: 'спасти',
        mostPopularSiteTitle: 'Добавить новый сайт в список самых популярных',
        sitetitle: 'Сайт',
        position: 'Позиция',
        close: 'Закрывать',
        tabDetails: 'Детали вкладки',
        private: 'частный',
        public: 'общественный',
        share: 'Поделиться',
        copy: 'копировать ссылку',
      },
      forgotPassword: {
        title: 'Забыл пароль',
        forgotBtn: 'ЗАБЫЛ ПАРОЛЬ',
      },
      resetPassword: {
        title:'Сброс пароля',
        resetBtn: 'СБРОС ПАРОЛЯ',
        passwordMinLength: 'Пароль должен быть длиннее 8 символов.',
      },
      register: {
        title: 'Создайте свою учетную запись AllMyFaves',
        email: 'Эл. адрес',
        username: 'Имя пользователя',
        password: 'Пароль',
        requiredField: 'Обязательное поле',
        registerBtn: 'Создать аккаунт',
        haveAccount: 'Иметь аккаунт?',
        signInBtn: 'Войти',
        acceptTC: 'Я принимаю Я согласен',
        pleaseAcceptTC: 'Пожалуйста, примите Условия использования',
        termsConditions: 'T&C',
        visualBookmarks: 'Визуальные закладки',
        visualBookmarksTxt: 'Добавляйте и управляйте всеми своими закладками под одной крышей.',
        discoverMore: 'Узнайте больше',
        discoverMoreTxt: 'Найдите множество отличных веб-сайтов на любую тему.',
        faveIt: 'Fave It',
        faveItTxt: 'С легкостью сохраняйте избранное, используя кнопку «Добавить в избранное».',
        everywhere: 'Где угодно',
        everywhereTxt: 'Используйте его везде. На любом компьютере, смартфоне или планшете.',
      },
      aboutUs: {
        aboutUsPageTitle: 'About Us',
        homepageTitle: 'All My Faves is the Ultimate Homepage',
        homepageContent: 'We like to keep it simple. Why search? is the essence of AllMyFaves\' philosophy. We believe the Internet should be an inspiring, easy and free experience for everyone. We, too, think that there must be an appealing and simple alternative to traditional Internet navigation. That is why we have taken up the task of simplification so that end-users could find what they\'re looking for quickly, whether they are interested in Entertainment, Games, Kids, Shopping, Travel or anything else. With AllMyFaves, users can smoothly cruise on the vast Internet highway without wasting valuable time or having to sieve through irrelevant search results.',
        homepageContent2: 'Our visual platform not only directs users to their sought-after information fast but also introduces them to new and exciting sites, both of the same and different categories. We achieve this through a strict selection process and a back-and-forth dialogue among the AllMyFaves team members. This way, AllMyFaves acts as a pioneering force of Internet browsing, searching and learning, thereby offering nothing but the absolute best of what the web has to offer. Today, when much of the searches we perform produce considerable numbers of spam, fraud and aggregation sites, we feel someone needs to step up and sift through the Internet so that Internet users\' experience is a positive, to the point and no-nonsense one. AllMyfaves has a calling and we\'re dead set on doing it right. What a better way to do so than by the use of the human brain.',
        historyTitle: 'History and Approach',
        historyContent: 'Back in 2006, Shachar Pessis grew frustrated by the cumbersome and convoluted nature of the Internet. To find something on the web, he had to type in URLs, use his ever-increasing list of bookmarks, enter key words in search engine boxes, or be acquainted with an Internet savvy friend who could recommend relevant sites to him. Sure enough, Shachar learned he was not alone: 72.3 percent of adult Americans experience \'search engine fatigue\' either always, usually or sometimes when searching for a topic on the web ("The State of Search" - compiled by Autobytel Inc. with Kelton Research, October 2007). Discovering new sites was even more challenging and time-consuming, and rarely did Shachar accomplish this in a fast and easy manner.',
        historyContent2: 'Shachar shared his discontentment with Roy Pessis, his brother. Together they came to the conclusion that a better alternative must exist, one that applies to and is shareable by everyone, everywhere. The two brothers then held brainstorming sessions and came up with the AllMyFaves concept. They envisioned a virtual directory that includes a list of top and most visited sites in major daily-used subject categories, a list they knew would introduce new and interesting things to others as it grew larger. Shachar and Roy aimed for a simple way by which users can discover new fields of interest without having to think about these beforehand or surfing the Internet with the initial intent of learning new things.',
        historyContent3: 'The AllMyFaves\' Internet team scans the Internet for the best and latest sites on the web. Our experts are constantly hard at work in examining the Internet\'s various aspects, categories and sites. By doing so, we eliminate all the crudeness of the Internet and offer users a purified, virtually distilled version of the web. Becoming a Faves expert is no easy task; new \'fave selectors\' who come aboard AllMyFaves go through a personal and ongoing training program in order to perform high quality filtration of the thousands of sites they come across every week. Indeed, we are stern believers in human editing skills versus those of a machine or software.',
        myFavesTitle: 'My Faves',
        myFavesContent: 'The My Faves feature is one of AllMyfaves\' most exciting components (',
        myFavesContentLink: 'click here to join',
        myFavesContent2: '). Although we do our best in making AllMyFaves your one-stop-shop for all your Internet needs, we do recognize the fact that each user is into different fields of interests and activities. Therefore, we\'re giving users their own creation powers for free. Once you\'ve signed up and have logged in, you\'ll have the option of adding your choice of new sites.',
        weeklyFaves: 'Weekly Faves',
        weeklyFavesContent: 'Updated every Monday, the Weekly Faves are our hand-picked sites for that week. These are the sites we were most impressed by and the ones we wish to pay forward to our users. Think of it as the \'crème de la crème\' of the Internet on a weekly basis. Accordingly, the Weekly Faves offer users a little bit of everything each week with the added value of introducing new sites and fields of interest to users.',
        missedSomethingTitle: 'We Missed Something?',
        missedSomethingContent: 'Check out our ',
        missedSomethingLink: 'Frequently Asked Questions',
        missedSomethingLink2: 'write us',
        missedSomethingContent2: ' for more info. Or ',
        missedSomethingContent3: ' with whatever is on your mind.',
        joinUsTitle: 'Join Us',
      },
      press: {
        pressPageTitle: 'Press Room'
      },
      bookmark: {
        faveBookmarkPageTitle: 'Закладка',
        saveBookmark: 'Легко сохранять свои любимые',
      },
      contact: {
        title: 'Связаться с нами',
        subtitle1: 'Ваше мнение очень важно для нас. Если у вас есть комментарий, предложение, жалоба или рекомендация сайта / категории, напишите нам по адресу',
        subtitle2: 'или заполните форму ниже.',
        checkboxTitle: 'Откуда вы узнали о нас?',
        checkbox: {
          search: 'Поиск',
          referringSite: 'Ссылающийся сайт',
          tv: 'Телевидение',
          radio: 'Радио',
          newspaper: 'Газета',
          world: 'Мир рта'
        },
      },
      footer: {
        titleSection1: 'All My Faves',
        aboutUs: 'О нас',
        join: 'Присоединиться',
        tellAFriend: 'Рассказать другу',
        press: 'Нажмите',
        blog: 'Блог',
        adminArea: "Админка",
        support: 'Служба поддержки',
        faq: 'FAQ',
        wantMore: 'Хочу больше?',
        contactUs: 'Связаться с нами',
        oldWebsite: 'Old Website',
        downloadApp: "Скачать приложение",
        privacy: 'Политика конфиденциальности',
        terms: 'Условия эксплуатации',
        rights: 'Все права защищены.',
      },
      navbar: {
        topFaves: 'Top Faves',
        shop: 'Магазин',
        shopping: 'Покупка',
        apparel: 'Одежда',
        homeElectronics: 'Дом и электроника',
        discover: 'Обнаружить',
        weeklyFaves: 'Еженедельные избранное',
        weeklyGames: 'Еженедельные игры',
        favesByCountry: 'Любимые страны',
        blog: 'Блог',
        settings: 'Настройки',
        myFaves: 'Мои любимые',
        interests: 'Добавить интересы',
        faveButton: 'Кнопка Fave It',
        faq: 'FAQ',
        sideBarHome: 'Навигация',
        home: 'Дом',
        profile: 'Профиль',
        adminMenu: 'Меню администратора',
      },
    },
    es: {
      sidebar: {
        settings: 'Ajustes',
        account: 'La Cuenta',
        billing: 'La facturación',
        export: 'Exportar Favoritos',
      },
      userDashboard: 'Panel de Usuario',
      adminDashboard: 'Panel de Administración',
      mail: 'info@allmyfaves.com',
      blogPost: 'Post',
      admins: 'Administradores',
      missingValue: 'Campo requerido',
      usernameMinimumLength: 'El nombre de usuario debe tener más de 3 caracteres',
      validUsername: 'Proporcione una dirección de nombre de usuario válida.',
      logout: 'Cerrar sesión',
      mostPopular: 'Most Popular',
      weekley: 'Favoritos semanales',
      userCard: {
        privateProfile: 'Perfil privado',
        publicProfile: 'Perfil público',
        favorite: 'favorito',
        bookmark: 'marcador',
        share: 'Cuota',
      },
      account: {
        upload: 'Cargar una nueva foto ...',
        username: "Nombre de usuario",
        mail: 'Correo electrónico',
        privateAccount: 'Mantener mi cuenta privada',
        notifications: 'Receive notifications',
        updates: 'Recibe actualizaciones sobre todos mis favoritos',
        deleteAccount: 'Borrar cuenta',
        text1: 'Visualizar',
        text2: 'tu',
        text3: 'Marcadores',
      },
      bookmarklet: {
        title: 'Agregar un marcador',
        titleRequired: 'Título requerido',
        addNewTab: 'Agregar nueva pestaña',
        addBookmark: 'Añadir marcador',
        drag: 'Arrastra este botón a la barra de marcadores.',
        install: 'Para instalar el botón "Fave It":',
        installex1: 'Muestre sus marcadores haciendo clic en Ctrl + Shift + B en su teclado',
        installex2: 'Arrastra el botón "Favorito" a la barra de marcadores.',
        installex3: 'Cuando esté navegando por la web, presione el botón "Fave It" para marcar un sitio web en su cuenta.',
      },
      login: {
        title: 'Welcome back to',
        email: 'Correo electrónico',
        username: 'Nombre de usuario',
        password: 'Contraseña',
        requiredField: 'Campo requerido.',
        minLengthUsername: 'El nombre de usuario debe tener un mínimo de 3 caracteres.',
        minLengthPassword: 'La contraseña debe tener un mínimo de 5 caracteres.',
        forgotUsername: '¿Olvidó su nombre de usuario?',
        forgotPassword: 'Forgot password?',
        loginBtn: 'Acceso',
        noAccount: "¿No tienes una cuenta?",
        getStarted: 'Empezar',
        goToLogin: 'Ir a la página de inicio de sesión',
        confirmedAccount: 'La cuenta ha sido confirmada',
      },
      faves: {
        favesByCountryTitle: 'Favoritos por país',
        hide: 'Esconder',
        show: 'Show',
        topFavesTitle: 'Top Faves',
      },
      userTopTabs: {
        addBtn: 'Agrega tus propios favoritos',
        cellTitle: 'Detalles del sitio',
        validUrl: 'Url no es válido!',
        delete: 'Eliminar',
        logo: 'logo',
        save: 'ahorrar',
        mostPopularSiteTitle: 'Agregar nuevo sitio a la lista de los más populares',
        sitetitle: 'Sitio',
        position: 'Posición',
        close: 'Cerrar',
        tabDetails: 'Detalles de la pestaña',
        private: 'privado',
        public: 'público',
        share: 'Cuota',
        copy: 'copiar link',
      },
      forgotPassword: {
        title: 'Has olvidado tu contraseña',
        forgotBtn: 'HAS OLVIDADO TU CONTRASEÑA',
      },
      resetPassword: {
        title:'Restablecer la contraseña',
        resetBtn: 'RESTABLECER LA CONTRASEÑA',
        passwordMinLength: 'La contraseña debe tener más de 8 caracteres.',
      },
      register: {
        title: 'Crea tu cuenta AllMyFaves',
        email: 'Correo electrónico',
        username: 'Nombre de usuario',
        password: 'Contraseña',
        requiredField: 'Campo requerido.',
        registerBtn: 'Crear una cuenta',
        haveAccount: '¿Tener una cuenta?',
        signInBtn: 'Registrarse',
        acceptTC: 'Acepto',
        pleaseAcceptTC: 'Acepta los términos y condiciones',
        termsConditions: 'T&C',
        visualBookmarks: 'Marcadores visuales',
        visualBookmarksTxt: 'Agregue y administre todos sus marcadores bajo un mismo techo.',
        discoverMore: 'Discover More',
        discoverMoreTxt: 'Encuentre una variedad de sitios web increíbles sobre cualquier tema.',
        faveIt: 'Fave It',
        faveItTxt: 'Guarde fácilmente sus favoritos usando el botón "Fave It".',
        everywhere: 'En todos lados',
        everywhereTxt: 'Úselo en todas partes. En cualquier computadora, teléfono inteligente o tableta.',
      },
      aboutUs: {
        aboutUsPageTitle: 'About Us',
        homepageTitle: 'All My Faves is the Ultimate Homepage',
        homepageContent: 'We like to keep it simple. Why search? is the essence of AllMyFaves\' philosophy. We believe the Internet should be an inspiring, easy and free experience for everyone. We, too, think that there must be an appealing and simple alternative to traditional Internet navigation. That is why we have taken up the task of simplification so that end-users could find what they\'re looking for quickly, whether they are interested in Entertainment, Games, Kids, Shopping, Travel or anything else. With AllMyFaves, users can smoothly cruise on the vast Internet highway without wasting valuable time or having to sieve through irrelevant search results.',
        homepageContent2: 'Our visual platform not only directs users to their sought-after information fast but also introduces them to new and exciting sites, both of the same and different categories. We achieve this through a strict selection process and a back-and-forth dialogue among the AllMyFaves team members. This way, AllMyFaves acts as a pioneering force of Internet browsing, searching and learning, thereby offering nothing but the absolute best of what the web has to offer. Today, when much of the searches we perform produce considerable numbers of spam, fraud and aggregation sites, we feel someone needs to step up and sift through the Internet so that Internet users\' experience is a positive, to the point and no-nonsense one. AllMyfaves has a calling and we\'re dead set on doing it right. What a better way to do so than by the use of the human brain.',
        historyTitle: 'History and Approach',
        historyContent: 'Back in 2006, Shachar Pessis grew frustrated by the cumbersome and convoluted nature of the Internet. To find something on the web, he had to type in URLs, use his ever-increasing list of bookmarks, enter key words in search engine boxes, or be acquainted with an Internet savvy friend who could recommend relevant sites to him. Sure enough, Shachar learned he was not alone: 72.3 percent of adult Americans experience \'search engine fatigue\' either always, usually or sometimes when searching for a topic on the web ("The State of Search" - compiled by Autobytel Inc. with Kelton Research, October 2007). Discovering new sites was even more challenging and time-consuming, and rarely did Shachar accomplish this in a fast and easy manner.',
        historyContent2: 'Shachar shared his discontentment with Roy Pessis, his brother. Together they came to the conclusion that a better alternative must exist, one that applies to and is shareable by everyone, everywhere. The two brothers then held brainstorming sessions and came up with the AllMyFaves concept. They envisioned a virtual directory that includes a list of top and most visited sites in major daily-used subject categories, a list they knew would introduce new and interesting things to others as it grew larger. Shachar and Roy aimed for a simple way by which users can discover new fields of interest without having to think about these beforehand or surfing the Internet with the initial intent of learning new things.',
        historyContent3: 'The AllMyFaves\' Internet team scans the Internet for the best and latest sites on the web. Our experts are constantly hard at work in examining the Internet\'s various aspects, categories and sites. By doing so, we eliminate all the crudeness of the Internet and offer users a purified, virtually distilled version of the web. Becoming a Faves expert is no easy task; new \'fave selectors\' who come aboard AllMyFaves go through a personal and ongoing training program in order to perform high quality filtration of the thousands of sites they come across every week. Indeed, we are stern believers in human editing skills versus those of a machine or software.',
        myFavesTitle: 'My Faves',
        myFavesContent: 'The My Faves feature is one of AllMyfaves\' most exciting components (',
        myFavesContentLink: 'click here to join',
        myFavesContent2: '). Although we do our best in making AllMyFaves your one-stop-shop for all your Internet needs, we do recognize the fact that each user is into different fields of interests and activities. Therefore, we\'re giving users their own creation powers for free. Once you\'ve signed up and have logged in, you\'ll have the option of adding your choice of new sites.',
        weeklyFaves: 'Weekly Faves',
        weeklyFavesContent: 'Updated every Monday, the Weekly Faves are our hand-picked sites for that week. These are the sites we were most impressed by and the ones we wish to pay forward to our users. Think of it as the \'crème de la crème\' of the Internet on a weekly basis. Accordingly, the Weekly Faves offer users a little bit of everything each week with the added value of introducing new sites and fields of interest to users.',
        missedSomethingTitle: 'We Missed Something?',
        missedSomethingContent: 'Check out our ',
        missedSomethingLink: 'Frequently Asked Questions',
        missedSomethingLink2: 'write us',
        missedSomethingContent2: ' for more info. Or ',
        missedSomethingContent3: ' with whatever is on your mind.',
        joinUsTitle: 'Join Us',
      },
      press: {
        pressPageTitle: 'Press Room'
      },
      bookmark: {
        faveBookmarkPageTitle: 'El marcador',
        saveBookmark: 'Guarda fácilmente tus favoritos',
      },
      contact: {
        title: 'Contacta con nosotros',
        subtitle1: 'Tu opinión significa mucho para nosotros. Si tiene un comentario, sugerencia, queja o recomendación de un sitio / categoría, envíenos un correo electrónico a',
        subtitle2: 'o complete el formulario a continuación.',
        checkboxTitle: '¿Cómo te enteraste de nosotros?',
        checkbox: {
          search: 'Search',
          referringSite: 'Reffering Site',
          tv: 'Television',
          radio: 'Radio',
          newspaper: 'Newspaper',
          world: 'World Of Mouth'
        },
      },
      footer: {
        titleSection1: 'Todos mis favoritos',
        aboutUs: 'Sobre nosotros',
        join: 'Entrar',
        tellAFriend: 'Dile a un amigo',
        press: 'prensa',
        blog: 'Blog',
        adminArea: "Área de administración",
        support: 'Apoyo',
        faq: 'FAQ',
        wantMore: '¿Quieren más?',
        contactUs: 'Contacta con nosotros',
        oldWebsite: 'Old Website',
        downloadApp: "Download the App",
        privacy: 'Privacy Policy',
        terms: 'Condiciones de uso',
        rights: 'Reservados todos los derechos.',
      },
      navbar: {
        topFaves: 'Top Favoritos',
        shop: 'Tienda',
        shopping: 'Compras',
        apparel: 'Vestir',
        homeElectronics: 'Hogar y electrónica',
        discover: 'Descubrir',
        weeklyFaves: 'Favoritos Semanales',
        weeklyGames: 'Juegos Semanales',
        favesByCountry: 'Favoritos por país',
        blog: 'Blog',
        settings: 'Ajustes',
        myFaves: 'Mis favoritos',
        interests: 'Agregar intereses',
        faveButton: 'Botón Fave It',
        faq: 'FAQ',
        sideBarHome: 'Navegación',
        home: 'Hogar',
        profile: 'Perfil',
        adminMenu: 'Menú de administración',
      },
    },
  },
});

export default i18n;
