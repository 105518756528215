/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import { FINISHED_LOADING, IS_LOADING } from '@/store/modules/layout/layoutActions.type';
import SubscriptionCodesService from '@/services/subscriptionCodes.service';
import {
  FETCH_CODES,
  UPDATE_ACTIVE_SORT_FIELD_CODES,
  UPDATE_SEARCH_CODES,
  CLEAR_SEARCH_CODES,
  DELETE_CODE,
  ADD_CODE,
} from './codesActions.type';

import {
  FETCH_CODES_SUCCESS,
  UPDATE_ACTIVE_SORT_FIELD_CODES_M,
  UPDATE_SEARCH_CODES_M,
  CLEAR_SEARCH_CODES_M,
} from './codesMutations.type';


export default {
  state: {
    data: [],
    perPage: 25,
    currentPage: 1,
    lastPage: 1,
    from: 1,
    to: 10,
    total: 10,
    sort: [
      {
        name: 'ID ASC',
        key: 'id',
        dir: 'ASC',
        code: 'id_ASC',
        active: true,
      },
      {
        name: 'ID DESC',
        key: 'id',
        dir: 'DESC',
        code: 'id_DESC',
        active: false,
      },
      {
        name: 'A-Z by code',
        key: 'code',
        dir: 'ASC',
        code: 'code_ASC',
        active: false,
      },
      {
        name: 'Z-A by Code',
        key: 'code',
        dir: 'DESC',
        code: 'code_DESC',
        active: false,
      },
    ],
    search: '',
  },
  getters: {
    subscriptionCodesItems(state) {
      return state.data;
    },
    itemsPerPage(state) {
      return state.perPage;
    },
    subscriptionCodesCurrentPage(state) {
      return state.currentPage;
    },
    subscriptionCodesLastPage(state) {
      return state.lastPage;
    },
    subscriptionCodesFrom(state) {
      return state.from;
    },
    subscriptionCodesTo(state) {
      return state.to;
    },
    subscriptionCodesTotal(state) {
      return state.total;
    },
    subscriptionCodesSortFields(state) {
      return state.sort;
    },
    subscriptionCodesActiveSort(state) {
      const activeSorts = state.sort.filter(sort => sort.active === true);
      return activeSorts[0];
    },
    subscriptionCodesSearch(state) {
      return state.search;
    },
  },
  mutations: {
    [FETCH_CODES_SUCCESS](state, data) {
      state.data = data.data;
      state.perPage = parseInt(data.perPage, 10);
      state.currentPage = data.currentPage;
      state.lastPage = data.lastPage;
      state.from = data.from;
      state.to = data.to;
      state.total = data.total;
    },
    [UPDATE_ACTIVE_SORT_FIELD_CODES_M](state, data) {
      const newSortFields = [];
      state.sort.forEach((element) => {
        element.active = false;
        if (element.code === data) {
          element.active = true;
        }
        newSortFields.push(element);
      });

      Vue.set(state, 'sort', newSortFields);
    },
    [UPDATE_ACTIVE_SORT_FIELD_CODES_M](state, data) {
      Vue.set(state, 'search', data);
    },
    [CLEAR_SEARCH_CODES_M](state) {
      Vue.set(state, 'search', '');
    },
  },
  actions: {
    [FETCH_CODES](context, params) {
      return new Promise((resolve, reject) => {
        if (context.state.search && context.state.search !== '') {
          params.search = context.state.search;
        }
        const activeSortArr = context.state.sort.filter(sort => sort.active === true);
        if (activeSortArr.length > 0) {
          params.sortBy = activeSortArr[0].key;
          params.sortDir = activeSortArr[0].dir;
        }
        SubscriptionCodesService.query(params)
          .then((response) => {
            context.commit(FETCH_CODES_SUCCESS, response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [ADD_CODE](context, payload) {
      return new Promise((resolve, reject) => {
        context.dispatch(IS_LOADING).then(() => {
          SubscriptionCodesService.create(payload)
            .then((response) => {
              context.dispatch(FINISHED_LOADING);
              resolve(response.data);
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING);
              reject(error);
            });
        });
      });
    },
    [UPDATE_ACTIVE_SORT_FIELD_CODES](context, params) {
      context.commit(UPDATE_ACTIVE_SORT_FIELD_CODES_M, params);
    },
    [UPDATE_SEARCH_CODES](context, params) {
      context.commit(UPDATE_SEARCH_CODES_M, params);
    },
    [CLEAR_SEARCH_CODES](context) {
      context.commit(CLEAR_SEARCH_CODES_M);
    },
    [DELETE_CODE](context, payload) {
      return new Promise((resolve, reject) => {
        context.dispatch(IS_LOADING).then(() => {
          SubscriptionCodesService.delete(payload)
            .then((response) => {
              context.dispatch(FINISHED_LOADING).then(() => {
                resolve(response.data);
              });
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING).then(() => {
                reject(error);
              });
            });
        });
      });
    },
  },
};
