/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import UserTabsService from '@/services/userTabs.service';
import { FETCH_USER_TAB } from './userTabActions.type';
import { FETCH_USER_TAB_SUCCESS } from './userTabMutations.type';

export default {
  state: {
    userTabData: {},
  },
  getters: {
    userTabData(state) {
      return state.userTabData;
    },
  },
  mutations: {
    [FETCH_USER_TAB_SUCCESS](state, data) {
      Vue.set(state, 'userTabData', data.userTab);
    },
  },
  actions: {
    [FETCH_USER_TAB](context, id) {
      return new Promise((resolve, reject) => {
        UserTabsService.get(id)
          .then((response) => {
            context.commit(FETCH_USER_TAB_SUCCESS, response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
