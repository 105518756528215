/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import FavPagesService from '@/services/favPages.service';
import LocalStorageService from '@/services/localStorage.service';
import {
  FETCH_FAV_PAGE, RESET_FAV_PAGE, SET_LINES, ADD_BANNER_PAGE_TO_CLOSED_BANNERS,
} from '@/store/modules/favPage/favPageActions.type';
import {
  FETCH_FAV_PAGE_SUCCESS, RESET_FAV_PAGE_M, SET_LINES_M, ADD_BANNER_PAGE_TO_CLOSED_BANNERS_M,
} from '@/store/modules/favPage/favPageMutations.type';

export default {
  state: {
    favPageUserData: {},
    bannerPages: ['home', 'weeklyFaves', 'weeklyGames', 'TYPE_CATEGORY', 'TYPE_TOP_FAVES', 'TYPE_TOP_GAMES', 'TYPE_BLOGS'],
    banner: null,
    linesWithMinSpace: null,
    originalLines: null,
    linesWithAscSorting: null,
    linesWithDescSorting: null,
  },
  getters: {
    favPageUserData(state) {
      return state.favPageUserData;
    },
    linesWithMinSpace(state) {
      return state.linesWithMinSpace;
    },
    originalLines(state) {
      return state.originalLines;
    },
    banner(state) {
      return state.banner;
    },
    bannerPages(state) {
      return state.bannerPages;
    },
    linesWithAscSorting(state) {
      return state.linesWithAscSorting;
    },
    linesWithDescSorting(state) {
      return state.linesWithDescSorting;
    },
  },
  mutations: {
    [FETCH_FAV_PAGE_SUCCESS](state, data) {
      const linesWithMinSpace = data.favPage.lines.map(line => ({
        ...line,
        space: 3,
      }));
      const sortedAsc = [...data.favPage.lines];
      const sortedDesc = [...data.favPage.lines];
      const linesWithAscSorting = sortedAsc.sort((a, b) => (a.name > b.name ? 1 : -1));
      const linesWithDescSorting = sortedDesc.sort((a, b) => (a.name < b.name ? 1 : -1));
      const originalLines = data.favPage.lines.map(line => ({
        ...line,
      }));
      const closedBannersBefore = LocalStorageService.getClosedBanners();
      let banner = decodeURI(data.favPage.banner);
      (closedBannersBefore).forEach((element) => {
        if (element.page === data.favPage.id && element.banner === banner) {
          banner = null;
        }
      });
      Vue.set(state, 'favPageUserData', data.favPage);
      Vue.set(state, 'linesWithMinSpace', linesWithMinSpace);
      Vue.set(state, 'originalLines', originalLines);
      Vue.set(state, 'banner', banner);
      Vue.set(state, 'linesWithAscSorting', linesWithAscSorting);
      Vue.set(state, 'linesWithDescSorting', linesWithDescSorting);
    },
    [ADD_BANNER_PAGE_TO_CLOSED_BANNERS_M](state, payload) {
      Vue.set(state, 'banner', null);
      LocalStorageService.setClosedBanners(payload.favPageId, payload.banner);
    },
    [RESET_FAV_PAGE_M](state) {
      Vue.set(state, 'favPageUserData', {});
      Vue.set(state, 'banner', null);
    },
    [SET_LINES_M](state, lines) {
      state.favPageUserData.lines = lines;
      Vue.set(state, 'favPageUserData', state.favPageUserData);
    },
  },
  actions: {
    [RESET_FAV_PAGE](context) {
      context.commit(RESET_FAV_PAGE_M);
    },
    [ADD_BANNER_PAGE_TO_CLOSED_BANNERS](context, payload) {
      context.commit(ADD_BANNER_PAGE_TO_CLOSED_BANNERS_M, payload);
    },
    [SET_LINES](context, payload) {
      context.commit(SET_LINES_M, payload.lines);
    },
    [FETCH_FAV_PAGE](context, id) {
      context.commit(RESET_FAV_PAGE_M);
      return new Promise((resolve, reject) => {
        FavPagesService.get(id)
          .then((response) => {
            context.commit(FETCH_FAV_PAGE_SUCCESS, response.data.data);
            // context.dispatch(FINISHED_LOADING).then(() => {
            resolve(response.data);
            // });
          })
          .catch((error) => {
            context.commit(RESET_FAV_PAGE_M);
            reject(error);
          });
      });
    },
  },
};
