/* eslint-disable import/no-cycle,no-shadow */
import LocalStorageService from '@/services/localStorage.service';
import UserService from '@/services/users.service';

import {
  CHANGE_CELL_LOADING,
  FINISH_CHANGE_CELL_LOADING,
} from '@/store/modules/layout/layoutActions.type';
import { UPDATE_USER_SUCCESS, ACCEPT_COOKIES_M } from '@/store/modules/user/userMutations.type';
import FilesService from '@/services/files.service';
import {
  GET_AUTH_USER_TABS,
} from '@/store/modules/auth/authUserTabs/authUserTabsActions.type';
import { FETCH_ADMIN_USER } from '@/store/modules/admin/resources/users/usersActions.type';
import {
  DELETE_USER, SEND_EMAIL, EXPORT_USER_CELLS, REGENERATE_USER_SPRITE, REGENERATE_USER_SPRITE_ALL_TAB, UPDATE_USER, UPLOAD_PROFILE_USER_IMAGE, ACCEPT_COOKIES,
} from './userActions.type';

const state = {
  user: LocalStorageService.getUser(),
  acceptCookies: LocalStorageService.getAcceptCookies(),
};

const getters = {
  userAuth(state) {
    return state.user;
  },
  acceptCookies(state) {
    return state.acceptCookies;
  },
};

const actions = {
  [DELETE_USER](context, payload) {
    return new Promise((resolve, reject) => {
      UserService.destroy(payload)
        .then((response) => {
          setTimeout(() => {
            resolve(response.data);
          }, 500);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [SEND_EMAIL](context, payload) {
    return new Promise((resolve, reject) => {
      UserService.sendEmail(payload)
        .then((response) => {
          setTimeout(() => {
            resolve(response.data);
          }, 500);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPDATE_USER](context, payload) {
    return new Promise((resolve, reject) => {
      UserService.update(payload.id, payload)
        .then((response) => {
          context.commit(UPDATE_USER_SUCCESS, response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [EXPORT_USER_CELLS](context, payload) {
    return new Promise((resolve, reject) => {
      UserService.export(payload)
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data], payload.exportType === 'xml' ? { type: 'text/xml' } : { type: 'application/pdf' }));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          if (payload.exportType === 'xml') {
            fileLink.setAttribute('download', 'exportCells.xml');
          } else if (payload.exportType === 'excel') {
            fileLink.setAttribute('download', 'exportCells.csv');
          }
          document.body.appendChild(fileLink);
          fileLink.click();
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [REGENERATE_USER_SPRITE](context, payload) {
    return new Promise((resolve, reject) => {
      context.dispatch(CHANGE_CELL_LOADING).then(() => {
        const { adminMode } = payload;
        UserService.regenerateSprite(payload)
          .then((response) => {
            if (adminMode && context.getters.userData) {
              context.dispatch(FETCH_ADMIN_USER, context.getters.userData.id).then();
            }
            context.dispatch(GET_AUTH_USER_TABS).then(() => {
              context.dispatch(FINISH_CHANGE_CELL_LOADING).then(() => {
                resolve(response.data);
              });
            });
          })
          .catch((error) => {
            context.dispatch(FINISH_CHANGE_CELL_LOADING).then(() => {
              reject(error);
            });
          });
      });
    });
  },
  [REGENERATE_USER_SPRITE_ALL_TAB](context, payload) {
    return new Promise((resolve, reject) => {
      context.dispatch(CHANGE_CELL_LOADING).then(() => {
        const { adminMode } = payload;
        UserService.regenerateUserSpriteAllTabs(payload)
          .then((response) => {
            if (adminMode && context.getters.userData) {
              context.dispatch(FETCH_ADMIN_USER, context.getters.userData.id).then();
            }
            context.dispatch(FINISH_CHANGE_CELL_LOADING).then(() => {
              resolve(response.data);
            });
          })
          .catch((error) => {
            context.dispatch(FINISH_CHANGE_CELL_LOADING).then(() => {
              reject(error);
            });
          });
      });
    });
  },
  [UPLOAD_PROFILE_USER_IMAGE](context, payload) {
    return new Promise((resolve, reject) => {
      FilesService.create(payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ACCEPT_COOKIES](context) {
    context.commit(ACCEPT_COOKIES_M);
  },
};
const mutations = {
  [UPDATE_USER_SUCCESS](state, data) {
    // update if user chages his info only
    // don't update if admin change a user's info
    if (state.user === null || state.user.id === data.data.user.id) {
      const modifiedUser = data.data.user;
      state.user = modifiedUser;
      LocalStorageService.storeUser(state.user);
    }
  },
  [ACCEPT_COOKIES_M](state) {
    LocalStorageService.setAcceptCookies();
    state.acceptCookies = true;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
