export const LOGIN = 'login';
export const SOCIAL_LOGIN = 'socialLogin';
export const SOCIAL_LOGIN_SUCCESS = 'socialLoginSuccess';
export const REGISTER = 'register';
export const LOGOUT = 'logout';
export const CONFIRM_ACCOUNT = 'confirmAccount';
export const CHECK_CONFIRM_ACCOUNT = 'checkConfirmAccount';
export const RESEND_CONFIRM_EMAIL = 'RESEND_CONFIRM_EMAIL';
export const FORGOT_PASSWORD = 'forgotPassword';
export const RESET_PASSWORD = 'resetPassword';
export const GET_AUTH_USER_TABS = 'getAuthUserTabs';
export const DELETE_USER = 'deleteUser';
export const UPDATE_USER = 'updateUser';
export const SET_USER = 'setUser';
