/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import SettingService from '@/services/settings.service';
import { FINISHED_LOADING, IS_LOADING } from '@/store/modules/layout/layoutActions.type';
import {
  FETCH_SETTINGS,
  UPDATE_ACTIVE_SORT_FIELD_SETTINGS,
  UPDATE_SEARCH_SETTINGS,
  CLEAR_SEARCH_SETTINGS,
  DELETE_SETTING,
} from './settingsActions.type';

import {
  FETCH_SETTINGS_SUCCESS,
  UPDATE_ACTIVE_SORT_FIELD_SETTINGS_M,
  UPDATE_SEARCH_SETTINGS_M,
  CLEAR_SEARCH_SETTINGS_M,
} from './settingsMutations.type';


export default {
  state: {
    data: [],
    perPage: 25,
    currentPage: 1,
    lastPage: 1,
    from: 1,
    to: 10,
    total: 10,
    sort: [
      {
        name: 'ID ASC',
        key: 'id',
        dir: 'ASC',
        code: 'id_ASC',
        active: true,
      },
      {
        name: 'ID DESC',
        key: 'id',
        dir: 'DESC',
        code: 'id_DESC',
        active: false,
      },
      {
        name: 'A-Z by key',
        key: 'key',
        dir: 'ASC',
        code: 'key_ASC',
        active: false,
      },
      {
        name: 'Z-A by key',
        key: 'key',
        dir: 'DESC',
        code: 'key_DESC',
        active: false,
      },
      {
        name: 'A-Z by value',
        key: 'value',
        dir: 'ASC',
        code: 'value_ASC',
        active: false,
      },
      {
        name: 'Z-A by value',
        key: 'value',
        dir: 'DESC',
        code: 'value_DESC',
        active: false,
      },
    ],
    search: '',
  },
  getters: {
    settingsItems(state) {
      return state.data;
    },
    itemsPerPage(state) {
      return state.perPage;
    },
    settingsCurrentPage(state) {
      return state.currentPage;
    },
    settingsLastPage(state) {
      return state.lastPage;
    },
    settingsFrom(state) {
      return state.from;
    },
    settingsTo(state) {
      return state.to;
    },
    settingsTotal(state) {
      return state.total;
    },
    settingsSortFields(state) {
      return state.sort;
    },
    settingsActiveSort(state) {
      const activeSorts = state.sort.filter(sort => sort.active === true);
      return activeSorts[0];
    },
    settingsSearch(state) {
      return state.search;
    },
  },
  mutations: {
    [FETCH_SETTINGS_SUCCESS](state, data) {
      state.data = data.data;
      state.perPage = parseInt(data.perPage, 10);
      state.currentPage = data.currentPage;
      state.lastPage = data.lastPage;
      state.from = data.from;
      state.to = data.to;
      state.total = data.total;
    },
    [UPDATE_ACTIVE_SORT_FIELD_SETTINGS_M](state, data) {
      const newSortFields = [];
      state.sort.forEach((element) => {
        element.active = false;
        if (element.code === data) {
          element.active = true;
        }
        newSortFields.push(element);
      });

      Vue.set(state, 'sort', newSortFields);
    },
    [UPDATE_SEARCH_SETTINGS_M](state, data) {
      Vue.set(state, 'search', data);
    },
    [CLEAR_SEARCH_SETTINGS_M](state) {
      Vue.set(state, 'search', '');
    },
  },
  actions: {
    [FETCH_SETTINGS](context, params) {
      return new Promise((resolve, reject) => {
        if (context.state.search && context.state.search !== '') {
          params.search = context.state.search;
        }
        const activeSortArr = context.state.sort.filter(sort => sort.active === true);
        if (activeSortArr.length > 0) {
          params.sortBy = activeSortArr[0].key;
          params.sortDir = activeSortArr[0].dir;
        }
        SettingService.query(params)
          .then((response) => {
            context.commit(FETCH_SETTINGS_SUCCESS, response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [UPDATE_ACTIVE_SORT_FIELD_SETTINGS](context, params) {
      context.commit(UPDATE_ACTIVE_SORT_FIELD_SETTINGS_M, params);
    },
    [UPDATE_SEARCH_SETTINGS](context, params) {
      context.commit(UPDATE_SEARCH_SETTINGS_M, params);
    },
    [CLEAR_SEARCH_SETTINGS](context) {
      context.commit(CLEAR_SEARCH_SETTINGS_M);
    },
    [DELETE_SETTING](context, payload) {
      return new Promise((resolve, reject) => {
        context.dispatch(IS_LOADING).then(() => {
          SettingService.delete(payload)
            .then((response) => {
              context.dispatch(FINISHED_LOADING).then(() => {
                resolve(response.data);
              });
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING).then(() => {
                reject(error);
              });
            });
        });
      });
    },
  },
};
