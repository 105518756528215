export const SET_SELECTED_TAB_INDEX_M = 'setSelectedTabIndexMutation';
export const SET_SELECTED_TAB_M = 'setSelectedTabMutation';
export const RESET_SELECTED_TAB_INDEX_M = 'resetSelectedTabIndexMutation';
export const DELETE_USER_TAB_SUCCESS = 'deleteUserTabSuccess';

export const UPDATE_TAB_CELL_SUCCESS = 'updateTabCellSuccess';
export const DELETE_TAB_CELL_SUCCESS = 'deleteTabCellSuccess';

export const SET_TAB_CELL_EDITABLE_ID_M = 'setTabCellEditableIDMutation';
export const RESET_TAB_CELL_EDITABLE_ID_M = 'resetTabCellEditableIDMutation';
export const SET_HIDE_LINES_M = 'setHideLinesMutation';

export const CREATE_USER_TAB_SUCCESS = 'createUserTabSuccess';

export const GET_AUTH_USER_TABS_SUCCESS = 'getAuthUserTabsSuccess';

export const SET_TAB_MODAL_VALUE_M = 'setTabModalValueMutation';
export const SET_SHARE_TAB_MODAL_VALUE_M = 'setShareTabModalValueMutation';
export const SET_EDITABLE_TAB_MODAL_VALUE_M = 'setEditableTabModalValueMutation';
