/* eslint-disable import/no-cycle */
import Vue from 'vue';

const MODULE_ROUTE = 'email';

export default {
  postContactForm(id, data) {
    return Vue.axios.post(`${MODULE_ROUTE}/${id}`, data).catch((error) => {
      throw new Error(`${error}`);
    });
  },
};
