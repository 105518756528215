/* eslint-disable import/no-cycle */
import Vue from 'vue';

const MODULE_ROUTE = 'settings';

export default {
  query(params) {
    return Vue.axios.get(MODULE_ROUTE, { params }).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  get(id) {
    return Vue.axios.get(`${MODULE_ROUTE}/${id}`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  getWeeklyLine() {
    return Vue.axios.get(`${MODULE_ROUTE}/get_weekly_line`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  getUserPageMsg() {
    return Vue.axios.get(`${MODULE_ROUTE}/get-myFaves-msg`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  create(data) {
    return Vue.axios.post(MODULE_ROUTE, data).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  update(id, data) {
    return Vue.axios.post(`${MODULE_ROUTE}/update/${id}`, data).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  delete(id) {
    return Vue.axios.delete(`${MODULE_ROUTE}/${id}`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
};
