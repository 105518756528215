export const SIDEBAR_TOGGLE = 'toggleSidebar';
export const SIDEBAR_CLOSE = 'closeSidebar';

export const IS_LOADING = 'isLoading';
export const FINISHED_LOADING = 'finnishedLoading';

export const SET_LANGUAGE_APP = 'setLanguageApp';
export const SET_TOUR = 'setTour';


export const SAVE_CELL_LOADING = 'saveCellLoading';
export const FINISH_SAVE_CELL_LOADING = 'finishSaveCellLoading';
export const CHANGE_CELL_LOADING = 'changeCellLoading';
export const FINISH_CHANGE_CELL_LOADING = 'finishChangeCellLoading';
