/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import FavPagesService from '@/services/favPages.service';
import FavLinesService from '@/services/favLines.service';
import { FINISHED_LOADING, IS_LOADING } from '@/store/modules/layout/layoutActions.type';
import {
  FETCH_FAV_PAGES,
  UPDATE_ACTIVE_SORT_FIELD_FAV_PAGES,
  UPDATE_SEARCH_FAV_PAGES,
  CLEAR_SEARCH_FAV_PAGES,
  ADMIN_CREATE_FAV_PAGE,
  ADMIN_UPDATE_FAV_PAGE,
  ADMIN_DELETE_FAV_PAGE,
  ADMIN_UPDATE_FAV_LINE_POSITION,
  ADMIN_UPDATE_FAV_CELL_POSITION_IN_FAV_LINE,
  ADMIN_DELETE_FAV_CELL_FOR_FAVLINE,
} from './favPagesActions.type';
import {
  FETCH_FAV_PAGES_SUCCESS,
  UPDATE_ACTIVE_SORT_FIELD_FAV_PAGES_M,
  UPDATE_SEARCH_FAV_PAGES_M,
  CLEAR_SEARCH_FAV_PAGES_M,
} from './favPagesMutations.type';


export default {
  state: {
    data: [],
    perPage: 25,
    currentPage: 1,
    lastPage: 1,
    from: 1,
    to: 10,
    total: 10,
    sort: [
      {
        name: 'ID ASC',
        key: 'id',
        dir: 'ASC',
        code: 'id_ASC',
        active: true,
      },
      {
        name: 'ID DESC',
        key: 'id',
        dir: 'DESC',
        code: 'id_DESC',
        active: false,
      },
      {
        name: 'A-Z by type',
        key: 'type',
        dir: 'ASC',
        code: 'key_ASC',
        active: false,
      },
      {
        name: 'Z-A by type',
        key: 'type',
        dir: 'DESC',
        code: 'key_DESC',
        active: false,
      },
      {
        name: 'A-Z by title',
        key: 'title',
        dir: 'ASC',
        code: 'title_ASC',
        active: false,
      },
      {
        name: 'Z-A by title',
        key: 'title',
        dir: 'DESC',
        code: 'title_DESC',
        active: false,
      },
    ],
    search: '',
  },
  getters: {
    favPagesItems(state) {
      return state.data;
    },
    favPagesPerPage(state) {
      return state.perPage;
    },
    favPagesCurrentPage(state) {
      return state.currentPage;
    },
    favPagesLastPage(state) {
      return state.lastPage;
    },
    favPagesFrom(state) {
      return state.from;
    },
    favPagesTo(state) {
      return state.to;
    },
    favPagesTotal(state) {
      return state.total;
    },
    favPagesSortFields(state) {
      return state.sort;
    },
    favPagesActiveSort(state) {
      const activeSorts = state.sort.filter(sort => sort.active === true);
      return activeSorts[0];
    },
    favPagesSearch(state) {
      return state.search;
    },
  },
  mutations: {
    [FETCH_FAV_PAGES_SUCCESS](state, data) {
      state.data = data.data;
      state.perPage = parseInt(data.perPage, 10);
      state.currentPage = data.currentPage;
      state.lastPage = data.lastPage;
      state.from = data.from;
      state.to = data.to;
      state.total = data.total;
    },
    [UPDATE_ACTIVE_SORT_FIELD_FAV_PAGES_M](state, data) {
      const newSortFields = [];
      state.sort.forEach((element) => {
        element.active = false;
        if (element.code === data) {
          element.active = true;
        }
        newSortFields.push(element);
      });

      Vue.set(state, 'sort', newSortFields);
    },
    [UPDATE_SEARCH_FAV_PAGES_M](state, data) {
      Vue.set(state, 'search', data);
    },
    [CLEAR_SEARCH_FAV_PAGES_M](state) {
      Vue.set(state, 'search', '');
    },
  },
  actions: {
    [FETCH_FAV_PAGES](context, params) {
      return new Promise((resolve, reject) => {
        // check search
        if (context.state.search && context.state.search !== '') {
          params.search = context.state.search;
        }
        // check active sort
        const activeSortArr = context.state.sort.filter(sort => sort.active === true);
        if (activeSortArr.length > 0) {
          params.sortBy = activeSortArr[0].key;
          params.sortDir = activeSortArr[0].dir;
        }

        // do the actual API call
        FavPagesService.query(params)
          .then((response) => {
            context.commit(FETCH_FAV_PAGES_SUCCESS, response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [UPDATE_ACTIVE_SORT_FIELD_FAV_PAGES](context, params) {
      context.commit(UPDATE_ACTIVE_SORT_FIELD_FAV_PAGES_M, params);
    },
    [UPDATE_SEARCH_FAV_PAGES](context, params) {
      context.commit(UPDATE_SEARCH_FAV_PAGES_M, params);
    },
    [CLEAR_SEARCH_FAV_PAGES](context) {
      context.commit(CLEAR_SEARCH_FAV_PAGES_M);
    },
    [ADMIN_CREATE_FAV_PAGE](context, params) {
      return new Promise((resolve, reject) => {
        context.dispatch(IS_LOADING).then(() => {
          // do the actual API call
          FavPagesService.create(params)
            .then((response) => {
              context.dispatch(FINISHED_LOADING).then();
              resolve(response.data);
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING).then();
              reject(error);
            });
        });
      });
    },
    [ADMIN_UPDATE_FAV_PAGE](context, params) {
      return new Promise((resolve, reject) => {
        context.dispatch(IS_LOADING).then(() => {
          // do the actual API call
          FavPagesService.update(params)
            .then((response) => {
              context.dispatch(FINISHED_LOADING).then();
              resolve(response.data);
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING).then();
              reject(error);
            });
        });
      });
    },
    [ADMIN_DELETE_FAV_PAGE](context, payload) {
      return new Promise((resolve, reject) => {
        context.dispatch(IS_LOADING).then(() => {
          FavPagesService.destroy(payload)
            .then((response) => {
              context.dispatch(FINISHED_LOADING).then(() => {
                resolve(response.data);
              });
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING).then(() => {
                reject(error);
              });
            });
        });
      });
    },
    [ADMIN_DELETE_FAV_CELL_FOR_FAVLINE](context, payload) {
      return new Promise((resolve, reject) => {
        context.dispatch(IS_LOADING).then(() => {
          FavPagesService.destroyFavCell(payload)
            .then((response) => {
              context.dispatch(FINISHED_LOADING).then(() => {
                resolve(response.data);
              });
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING).then(() => {
                reject(error);
              });
            });
        });
      });
    },
    [ADMIN_UPDATE_FAV_LINE_POSITION](context, payload) {
      return new Promise((resolve, reject) => {
        context.dispatch(IS_LOADING).then(() => {
          FavLinesService.updatePosition(payload)
            .then((response) => {
              context.dispatch(FINISHED_LOADING).then();
              resolve(response.data);
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING).then();
              reject(error);
            });
        });
      });
    },
    [ADMIN_UPDATE_FAV_CELL_POSITION_IN_FAV_LINE](context, payload) {
      return new Promise((resolve, reject) => {
        context.dispatch(IS_LOADING).then(() => {
          FavPagesService.updateCellPosition(payload)
            .then((response) => {
              context.dispatch(FINISHED_LOADING).then();
              resolve(response.data);
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING).then();
              reject(error);
            });
        });
      });
    },
  },
};
