<template>
  <div>
    <md-dialog :md-active.sync="isModalVisible" class="md-dialog-tab"
    @keydown.esc="close">
      <form novalidate class="cell-modal"
            v-if="!upgradeModal"
            @submit.prevent="validateSaveCell" enctype="multipart/form-data">

        <md-dialog-title class="modal-header"> {{ $t('userTopTabs.cellTitle') }}
          <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close modal"
          >
            <span class="material-icons">close</span>
          </button>
        </md-dialog-title>

        <md-dialog-content>
          <md-progress-bar class="md-accent" md-mode="indeterminate" v-if="saveCellLoading"/>
          <md-content class="modal-body" id="modalDescription">
            <input class="modal-input form-control autosuggest__input input-with-border" v-model="form.link"
            @blur="handleBlur"
            @keyup="selectSite" placeholder="URL (example.com)">
            <div class="md-error" v-if="!$v.form.link.required && requiredFields">
              {{ $t('missingValue') }}
            </div>
            <div class="md-error" v-if="validUrl">
              {{ $t('userTopTabs.validUrl') }}
            </div>
            <input class="modal-input form-control autosuggest__input input-with-border" name="alt"
                   id="alt"
                   type="text"
                   v-model="form.alt"
                   placeholder="Title"
            />
            <div class="image-preview">
              <img class="cell-image-preview" :src="form.imagePreview" v-show="form.imagePreview">
            </div>
          </md-content>
        </md-dialog-content>

        <md-dialog-actions class="modal-footer">
            <div class="md-layout md-buttons-modal md-layout-flex-1">
              <div class="md-layout-item cell-modal-icons-container">
                <div class="md-layout-item">
                  <div @click="deleteCellConfirmation = true" v-show="editableCellCheck"
                    class="basic-btn trash-btn" title="Delete">
                  </div>
                  <div @click="$refs.file.click()" class="basic-btn logo-btn"
                    :style="[editableCellCheck ? {'margin-left' : '34px'} : {'margin-left': '0px'}]"
                    title="Upload">
                  </div>
                  <input type="file" ref="file" id="file" class="filestyle" data-count="0" style="display: none;"
                         @change="onFileChange">
                </div>
              </div>
              <md-button class="md-raised md-primary site-save-button"
                         type="submit" style="margin-bottom: 30px;"
                         :disabled="!saveBtn">
                {{ $t('userTopTabs.save') }}
              </md-button>
            </div>
        </md-dialog-actions>
      </form>

      <subscription-modal v-else @close="close"
      limitType="cells"></subscription-modal>

    </md-dialog>

    <md-dialog-confirm class="account-delete-confirmation delete-tab"
                       :md-active.sync="deleteCellConfirmation"
                       md-title="Delete Cell"
                       :md-content="deleteItem"
                       md-confirm-text="Yes"
                       md-cancel-text="No"
                       @md-confirm="deleteCell" />
  </div>
</template>


<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import formHelpersMixin from '@/mixins/formHelpersMixin';
import saveCellLoaderMixin from '@/mixins/saveCellLoaderMixin';

import { CHECK_SITE } from '@/store/modules/admin/single/site/siteActions.type';
import {
  CREATE_TAB_CELL,
  DELETE_TAB_CELL,
  UPDATE_TAB_CELL,
  UPLOAD_IMAGE_TAB_CELL,
} from '@/store/modules/auth/authUserTabs/authUserTabsActions.type';
import SubscriptionModal from './SubscriptionModal.vue';

export default {
  name: 'CellModal',
  props: {
    isCellModalVisible: Boolean,
  },
  components: {
    SubscriptionModal,
  },
  mixins: [
    validationMixin,
    formHelpersMixin,
    saveCellLoaderMixin,
  ],
  data: () => ({
    form: {
      id: null,
      link: null,
      tabId: null,
      siteId: null,
      alt: null,
      fileId: null,
      file: null,
      imagePreview: null,
      timeout: null,
      uploadedFilePreview: null,
    },
    validUrl: false,
    file: '',
    requiredFields: false,
    cell: null,
    editableCellCheck: false,
    adminMode: false,
    publicPath: process.env.BASE_URL,
    deleteCellConfirmation: false,
    enableSaveBtn: true,
    isAddModal: false,
  }),
  computed: {
    isModalVisible() {
      this.initForm();
      return this.isCellModalVisible;
    },
    saveBtn() {
      return this.enableSaveBtn;
    },
    deleteItem() {
      return `Do You want To delete ${this.form.link} cell?`;
    },
    isAdmin() {
      return this.$store.getters.user.role === 'admin';
    },
    selectCellObj() {
      if (this.getAdminMode) {
        return this.$store.getters.selectedTabCellObjToEditAdmin;
      }
      return this.$store.getters.selectedTabCellObjToEdit;
    },
    getNumberOfTabCells() {
      return this.$store.getters.selectedTab
        ? this.$store.getters.selectedTab?.tabCells?.length
        : 0;
    },
    canAddMoreCells() {
      return (this.$store.getters.user)
        ? ((this.getNumberOfTabCells < this.$store.getters.user.plan_cells_number
          && !this.$store.getters.user.is_exceed_total_cells_in_all_tabs)
          || this.$store.getters.user.is_in_first_period)
        : true;
    },
    upgradeModal() {
      return (this.isAddModal && !this.canAddMoreCells && !this.isAdmin);
    },
    getAdminMode() {
      return this.$store.getters.adminMode;
    },
  },
  validations: {
    form: {
      link: {
        required,
      },
    },
  },
  mounted() {
    this.initForm();
  },
  methods: {
    initForm() {
      this.editCellObj = null;
      if (typeof this.selectCellObj === 'number') {
        this.form.id = this.selectCellObj;
        this.form.tabId = this.selectCellObj;
        this.isAddModal = true;
      } else if (typeof this.selectCellObj === 'object') {
        this.editCellObj = this.selectCellObj ?? null;
        this.editableCellCheck = !!this.selectCellObj;
        if (this.selectCellObj) {
          this.form.id = this.selectCellObj.id;
          this.form.link = this.selectCellObj.link;
          this.form.tabId = this.selectCellObj.tab_id;
          this.form.siteId = this.selectCellObj.site_id;
          this.form.alt = this.selectCellObj.alt;
          this.form.fileId = this.selectCellObj.file_id;
          this.form.imagePreview = this.selectCellObj.public_path;
          this.isAddModal = false;
        }
      }
    },
    handleBlur(e) {
      this.selectSite(e, true);
    },
    selectSite(e, unfocus = false) {
      // do the process after pressing enter or unfocus the input or after passing 10 sec from last change
      const isEnter = e.keyCode === 13;
      const interVal = isEnter || unfocus ? 0 : 10000;

      this.enableSaveBtn = false;
      clearTimeout(this.timeout);

      if (this.urlValidation(this.form.link)) {
        this.validUrl = false;
        this.timeout = setTimeout(() => {
          const filters = {
            link: this.form.link,
          };
          this.$store.dispatch(CHECK_SITE, filters)
            .then((response) => {
              const item = response.data.site ?? null;
              this.form.link = filters.link;
              this.form.siteId = item ? item.id : null;
              this.form.fileId = item ? item.fileId : null;
              this.form.imagePreview = item ? item.public_path : `${this.publicPath}allmyfaves-logo.png`;
              this.form.image = item ? item.public_path : `${this.publicPath}allmyfaves-logo.png`;
              this.$log.info('CHECK_SITE', response);
              this.enableSaveBtn = true;
            })
            .catch((error) => {
              this.$log.error('CHECK_SITE', error);
            });
        }, interVal);
      } else {
        this.validUrl = true;
      }
    },
    onFileChange(e) {
      const image = e.target.files[0];
      this.form.file = image;
      this.saveImage(image);
      this.createImage(image);
    },
    createImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.form.imagePreview = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    urlValidation(url) {
      // const pattern = new RegExp('^(https?:\\/\\/)?' // protocol
      //   + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
      //   + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) link
      //   + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
      //   + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
      //   + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
      // return !!pattern.test(url);

      if (url && url.match(/(http(s)?:\/\/.)?(www\.)?(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)(:(\d+))?\/?/i)) {
        return true;
      }
      return false;
    },
    saveImage(image) {
      this.form.file = image;
      const formData = new FormData();
      formData.append('file', this.form.file);
      formData.append('tab_id', this.form.tabId);
      formData.append('context', 'TAB_CELL_IMAGE');
      this.$store.dispatch(UPLOAD_IMAGE_TAB_CELL, formData)
        .then((response) => {
          this.$log.info('UPLOAD_IMAGE_TAB_CELL', response);
          this.form.fileId = response.data.file.id;
          this.$toasted.show(response.message, {
            type: 'success',
            duration: 5000,
            icon: 'check',
          });
        })
        .catch((error) => {
          this.$log.error('UPLOAD_IMAGE_TAB_CELL', error);
          this.$toasted.error(error.message, {
            type: 'error',
            duration: 5000,
            icon: 'alert',
          });
        });
    },
    validateSaveCell() {
      this.$v.$touch();
      this.errors = {};
      if (!this.$v.$invalid) {
        this.saveCell();
      } else {
        this.requiredFields = true;
      }
    },
    saveCell() {
      // TODO save image in db and create title column in sites
      const payload = {
        id: this.form.id ?? null,
        // eslint-disable-next-line no-nested-ternary
        tab_id: this.form.tabId ?? this.$store.getters.selectedTabCellIdToEdit,
        site_id: this.form.siteId ?? null,
        link: this.form.link ?? null,
        alt: this.form.alt ?? null,
        file_id: this.form.fileId ?? null,
        drag: false,
        adminMode: this.getAdminMode,
        user_id: this.adminMode ? this.form.id : null,
      };
      // Same store dispatch used for update and create using ternary operator
      this.$store.dispatch((this.editCellObj && this.editableCellCheck ? UPDATE_TAB_CELL : CREATE_TAB_CELL), payload)
        .then((response) => {
          this.$log.info((this.editCellObj && this.editableCellCheck ? UPDATE_TAB_CELL : CREATE_TAB_CELL), payload);
          this.$toasted.show(response.message, {
            type: 'success',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
          });
          this.clearForm();
          this.cell = null;
        })
        .catch((error) => {
          this.$log.error((this.editCellObj && this.editableCellCheck ? UPDATE_TAB_CELL : CREATE_TAB_CELL), error);
          this.$toasted.show(error.message, {
            type: 'error',
            position: 'top-right',
            duration: 5000,
            icon: 'alert',
          });
          if (error.status === 'validation_error' && error.errors) {
            this.errors = error.errors;
          }
        });
    },
    deleteCell() {
      const cell = {
        id: this.form.id,
        tab_id: this.form.tabId,
        adminMode: this.getAdminMode,
      };
      this.enableSaveBtn = false;
      this.$store.dispatch(DELETE_TAB_CELL, cell)
        .then((response) => {
          this.$log.info('DELETE_TAB_CELL', response);
          this.$toasted.show(response.message, {
            type: 'success',
            duration: 5000,
            icon: 'check',
          });
          this.clearForm();
          this.enableSaveBtn = true;
        })
        .catch((error) => {
          this.$log.error('DELETE_TAB_CELL', error);
          this.$toasted.error(error.message, {
            type: 'error',
            duration: 5000,
            icon: 'alert',
          });
          this.enableSaveBtn = true;
        });
    },
    close() {
      this.$v.$reset();
      this.form.link = null;
      this.form.alt = null;
      this.form.siteId = null;
      this.form.fileId = null;
      this.form.imagePreview = null;
      this.form.image = null;
      this.$emit('close');
    },
    clearForm() {
      this.close();
      this.$v.$reset();
      this.form.link = null;
      this.form.siteId = null;
      this.form.alt = null;
      this.form.fileId = null;
      this.form.imagePreview = null;
      this.form.uploadedFilePreview = null;
      this.editableCellCheck = false;
    },
    changePlan() {
      this.$router.push({
        name: 'billing',
      });
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/layout/cell-modal.scss';
</style>
