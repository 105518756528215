/* eslint-disable import/no-cycle */
import Vue from 'vue';

const MODULE_ROUTE = 'fav-pages';

export default {
  query(params) {
    return Vue.axios.get(MODULE_ROUTE, { params }).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  get(id) {
    return Vue.axios.get(`${MODULE_ROUTE}/${id}`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  getFavLinesForFavPage(id) {
    return Vue.axios.get(`${MODULE_ROUTE}/${id}/fav-lines`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  create(data) {
    return Vue.axios.post(MODULE_ROUTE, data).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  update(data) {
    return Vue.axios.put(`${MODULE_ROUTE}/${data.id}`, data).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  destroy(id) {
    return Vue.axios.delete(`${MODULE_ROUTE}/${id}`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  // Admin Fav Line Part
  createAdminFavLine(data) {
    return Vue.axios.post(`${MODULE_ROUTE}/${data.pageId}/fav-lines`, data).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  updateAdminFavLine(data) {
    return Vue.axios.put(`${MODULE_ROUTE}/${data.pageId}/fav-lines/${data.lineId}`, data).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  destroyFavLine(data) {
    return Vue.axios.delete(`${MODULE_ROUTE}/${data.pageId}/fav-lines/${data.lineId}`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  createAdminFavLineCell(data) {
    return Vue.axios.post(`${MODULE_ROUTE}/${data.payload.favPageId}/fav-lines/${data.payload.lineId}/fav-cell`, data.formData).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  updateAdminFavLineCell(data) {
    const cellId = encodeURIComponent(data.payload.id);
    return Vue.axios.post(`${MODULE_ROUTE}/${data.payload.favPageId}/fav-lines/${data.payload.lineId}/fav-cell/${cellId}?_method=PUT`, data.formData).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  updateCellPosition(data) {
    return Vue.axios.put(`${MODULE_ROUTE}/${data.pageId}/fav-lines/${data.lineId}/fav-cell-position/${data.id}`, data).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  destroyFavCell(data) {
    const cellId = encodeURIComponent(data.cellId);
    return Vue.axios.delete(`${MODULE_ROUTE}/${data.pageId}/fav-lines/${data.lineId}/fav-cell/${cellId}`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  renegerateSprite(data) {
    return Vue.axios.post(`${MODULE_ROUTE}/${data.pageId}/fav-lines/${data.lineId}/regenerate-sprite`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
};
