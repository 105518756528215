/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import SettingService from '@/services/settings.service';
import { FINISHED_LOADING, IS_LOADING } from '@/store/modules/layout/layoutActions.type';
import {
  FETCH_SETTING, UPDATE_SETTING_A, CREATE_SETTING, FETCH_WEEKLY_SETTING_A, FETCH_USER_PAGE_MSG_A,
} from './settingActions.type';
import { FETCH_SETTING_SUCCESS, FETCH_WEEKLY_SETTING_M, FETCH_USER_PAGE_MSG_M } from './settingMutations.type';

export default {
  state: {
    settingData: {},
    weeklyLine: null,
    userPageMsg: null,
  },
  getters: {
    settingData(state) {
      return state.settingData;
    },
    weeklyLine(state) {
      return state.weeklyLine;
    },
    userPageMsg(state) {
      return state.userPageMsg;
    },
  },
  mutations: {
    [FETCH_SETTING_SUCCESS](state, data) {
      Vue.set(state, 'settingData', data);
    },
    [FETCH_WEEKLY_SETTING_M](state, data) {
      Vue.set(state, 'weeklyLine', data);
    },
    [FETCH_USER_PAGE_MSG_M](state, data) {
      Vue.set(state, 'userPageMsg', data);
    },
  },
  actions: {
    [CREATE_SETTING](context, params) {
      return new Promise((resolve, reject) => {
        context.dispatch(IS_LOADING).then(() => {
          SettingService.create(params)
            .then((response) => {
              context.dispatch(FINISHED_LOADING).then();
              resolve(response.data);
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING).then();
              reject(error);
            });
        });
      });
    },
    [FETCH_SETTING](context, id) {
      return new Promise((resolve, reject) => {
        SettingService.get(id)
          .then((response) => {
            context.commit(FETCH_SETTING_SUCCESS, response.data.data[0]);
            resolve(response.data.data[0]);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [UPDATE_SETTING_A](context, payload) {
      return new Promise((resolve, reject) => {
        SettingService.update(payload.id, payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [FETCH_WEEKLY_SETTING_A](context) {
      return new Promise((resolve, reject) => {
        SettingService.getWeeklyLine()
          .then((response) => {
            context.commit(FETCH_WEEKLY_SETTING_M, response.data.data[0]);
            resolve(response.data.data[0]);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [FETCH_USER_PAGE_MSG_A](context) {
      return new Promise((resolve, reject) => {
        SettingService.getUserPageMsg()
          .then((response) => {
            context.commit(FETCH_USER_PAGE_MSG_M, response.data.data[0].value);
            resolve(response.data.data[0].value);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
