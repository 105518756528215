<template>
  <div id="banner-container" class="banner-container">
    <button type="button" class="close-btn" @click="closePageBanner" aria-label="Close modal">
      <span class="material-icons">close</span>
    </button>
    <vue-editor id="banner-editor" :editorOptions="editorSettings" v-model="bannerContent" :disabled="isDisabled"></vue-editor>
  </div>
</template>


<script>
import { VueEditor } from 'vue2-editor';
import { ADD_BANNER_PAGE_TO_CLOSED_BANNERS } from '@/store/modules/favPage/favPageActions.type';

export default {
  name: 'RecBanner',
  components: {
    VueEditor,
  },
  props: {
    banner: {
      type: String,
      default: null,
    },
  },
  computed: {
    bannerContent() {
      return this.banner;
    },
    isDisabled() {
      return true;
    },
  },
  data() {
    return {
      editorSettings: {
        modules: {
          toolbar: false,
        },
      },
    };
  },
  methods: {
    closePageBanner() {
      const favPageId = this.$store.getters.favPageUserData.id;
      const banner = this.bannerContent;
      const payload = {
        favPageId,
        banner,
      };
      this.$store.dispatch(ADD_BANNER_PAGE_TO_CLOSED_BANNERS, payload);
    },
  },
};
</script>


<style scoped>
.banner-container {
  margin-bottom: 1.5rem;
}

.quillWrapper .ql-container.ql-snow {
  border: none !important;
}

.quillWrapper .ql-container.ql-snow:first-child {
  background: red;
}


.close-banner {
  float: right;
  color: white;
  cursor: pointer;
}

.close-btn {
  position: absolute;
  right: 0;
  border: none;
  font-size: 1rem;
  padding: 0.6rem 0.6rem 0rem 0rem;
  cursor: pointer;
  font-weight: bold;
  color: #fff;
  background: transparent;
  z-index: 999;
}
</style>
