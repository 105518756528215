/* eslint-disable import/no-cycle */
import Vue from 'vue';

const MODULE_ROUTE = 'sites';

export default {
  query(params) {
    return Vue.axios.get(MODULE_ROUTE, { params }).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  get(id) {
    return Vue.axios.get(`${MODULE_ROUTE}/${id}`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  create(data) {
    return Vue.axios.post(MODULE_ROUTE, data).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  createAdminSite(data) {
    return Vue.axios.post(`${MODULE_ROUTE}/admin/create`, data).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  update(id, data) {
    return Vue.axios.post(`${MODULE_ROUTE}/${id}`, data).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  destroy(id) {
    return Vue.axios.delete(`${MODULE_ROUTE}/${id}`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  checkSite(params) {
    return Vue.axios.get(`${MODULE_ROUTE}/check`, { params }).catch((error) => {
      throw new Error(`${error}`);
    });
  },
};
