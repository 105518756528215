/* eslint-disable import/no-cycle */
import Vue from 'vue';

const MODULE_ROUTE = 'users';

export default {
  query(params) {
    return Vue.axios.get(MODULE_ROUTE, { params }).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  queryAdmin(params) {
    return Vue.axios.get(`${MODULE_ROUTE}/admin/`, { params }).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  get(id) {
    return Vue.axios.get(`${MODULE_ROUTE}/${id}`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  getAdminMode(id) {
    return Vue.axios.get(`${MODULE_ROUTE}/admin/${id}`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  getUserByName(name) {
    return Vue.axios.get(`${MODULE_ROUTE}/my-faves/${name}`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  export(data) {
    return Vue.axios.post(`${MODULE_ROUTE}/export-cells`, { id: data.id, exportType: data.exportType }, { responseType: 'blob' }).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  regenerateSprite(data) {
    return Vue.axios.post(`${MODULE_ROUTE}/re-generate-user-tab-sprite/${data.id}/${data.tabId}`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  regenerateUserSpriteAllTabs(data) {
    return Vue.axios.post(`${MODULE_ROUTE}/re-generate-sprite/${data.id}`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  create(data) {
    return Vue.axios.post(MODULE_ROUTE, data).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  update(id, data) {
    return Vue.axios.put(`${MODULE_ROUTE}/${data.id}`, data).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  destroy(id) {
    return Vue.axios.delete(`${MODULE_ROUTE}/${id}`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  sendEmail(data) {
    return Vue.axios.post(`${MODULE_ROUTE}/send-email`, data).catch((error) => {
      throw new Error(`${error}`);
    });
  },
};
