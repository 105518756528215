/* eslint-disable import/no-cycle */
import Vue from 'vue';

const MODULE_ROUTE = 'files';

export default {
  create(data) {
    return Vue.axios.post(MODULE_ROUTE, data).catch((error) => {
      throw new Error(`${error}`);
    });
  },
};
